import { CourseNumberContainer } from "./CourseNumbers.styled";
import SubjectCourseSkeleton from "@Components/Skeletons/SubjectCourseSkeleton";
import {
  AnalyticsStyledBox,
  DataNumbers,
  IconWrapper,
  NumbersContainer,
  NumbersTitle,
} from "@Components/SubjectPage/AnalyticsBoxs/AnalyticsBoxs.styled";
import { TeachersDatasContext } from "context/Teachers.context";
import { CameraIcon } from "images/icons/CameraIcon";
import { GlassIcon } from "images/icons/GlassIcon";
import { UserSegauleIcon } from "images/icons/UserSegauleIcon";
import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";

const CourseNumbers = () => {
  const { courseDetails, isCourseDetailsLoading } = useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  return (
    <Fragment>
      {!isCourseDetailsLoading ? (
        <CourseNumberContainer>
          <AnalyticsStyledBox>
            <IconWrapper color={"rgba(254, 199, 108, 0.1)"}>
              <GlassIcon />
            </IconWrapper>
            <NumbersContainer>
              <NumbersTitle>{tCommon("views")}</NumbersTitle>
              <DataNumbers>{courseDetails?.view_count}</DataNumbers>
            </NumbersContainer>
          </AnalyticsStyledBox>
          <AnalyticsStyledBox>
            <IconWrapper color={"rgba(69, 170, 242,0.1)"}>
              <UserSegauleIcon />
            </IconWrapper>
            <NumbersContainer>
              <NumbersTitle>{tCommon("subscribers")}</NumbersTitle>
              <DataNumbers>{courseDetails?.subscribers_count}</DataNumbers>
            </NumbersContainer>
          </AnalyticsStyledBox>
          <AnalyticsStyledBox>
            <IconWrapper color={"rgba(19, 175, 114,0.1)"}>
              <CameraIcon />
            </IconWrapper>
            <NumbersContainer>
              <NumbersTitle>{tCommon("videos")}</NumbersTitle>
              <DataNumbers>{courseDetails?.videos_count}</DataNumbers>
            </NumbersContainer>
          </AnalyticsStyledBox>
          {/* <AnalyticsStyledBox style={{ opacity: 0 }}>
            <IconWrapper color={"rgba(174, 140, 204,0.1)"}>
              <SheetIcon />
            </IconWrapper>
            <NumbersContainer>
              <NumbersTitle>{tCommon("subscriptions")}</NumbersTitle>
              {courseDetails?.subscriptions_count ? (
                <DataNumbers>{courseDetails?.subscriptions_count}</DataNumbers>
              ) : (
                <DataNumbers>0</DataNumbers>
              )}
            </NumbersContainer>
          </AnalyticsStyledBox> */}
        </CourseNumberContainer>
      ) : (
        <SubjectCourseSkeleton />
      )}
    </Fragment>
  );
};

export default CourseNumbers;
