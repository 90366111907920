import {
  DashboardContainer,
  CenterDiv,
  MessageContainer,
  VideoProgressMessage,
  VideoProgressContainer,
  Place,
  StyledImage,
  StyledProgress,
  StyledPTag,
  StyledText,
  UploadedMessage,
  UploadedTitle,
  UploadTab,
  UploadTabsWrapper,
  UploadVideoContainer,
  UploadVideoInput,
} from "../AddLecturePopup/AddLecturePopup.styled";
import PopupsLayout from "@Components/Popups/PopupsLayout";
import { ErrorType } from "@Components/Popups/PopupsLayout/PopupsLayout.styled";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import { _CreateLecture, _GetVimeoVideoStatus, _UpdateIntroduction } from "@Services/Lecture";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { VIDEO, videoFileTypes } from "constants/Types";
import { TeachersDatasContext } from "context/Teachers.context";
import CheckIcon from "images/icons/CheckIcon";
import UploadIcon from "images/icons/UploadIcon";
import WaitToPlayIcon from "images/icons/WaitToPlayIcon";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as tus from "tus-js-client";
import { CourseSectionsType } from "types/Courses";
import { Lecture } from "types/Lecture";
import { getErrorMessage } from "utils/Forms";
import { generateVideoThumbnail } from "utils/Video";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
}

const AddIntroductionPopup = ({ isOpen, closePopup }: Props) => {
  const { courseSections, refetchCourseSections } = useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  // Form State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitValidation, setSubmitValidation] = useState<string>("");
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(true);
  const [introductionSection, setIntroductionSection] = useState<CourseSectionsType[]>([]);

  // Video State
  const [videoFile, setVideoFile] = useState<File>(null);
  const [lectureVideoType, setLectureVideoType] = useState<"with_id" | "upload">();
  const [videoThumbnails, setVideoThumbnails] = useState<string>("");
  const [videoUploadPersantage, setVideoUploadPersantage] = useState<string>("0%");
  const [videoUploadError, setVideoUploadError] = useState<string>("");
  const [videoValidation, setVideoValidation] = useState<string>("");

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<Lecture>();

  useEffect(() => {
    if (!isOpen) {
      reset();
      setSubmitValidation("");
      setLectureVideoType("upload");
      setVideoFile(null);
      setVideoThumbnails("");
      setVideoUploadPersantage("0%");
      setVideoUploadError("");
      setDisabledSubmitBtn(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (courseSections) {
      setIntroductionSection(courseSections.filter((section) => section?.is_introductory === true));
    }
  }, [courseSections]);

  // To ulpoad video to vimeo api
  const vimeoVideoUploadhandler = async (eventObject) => {
    const file = eventObject;
    setVideoFile(file);
    const fileSize = file.size.toString();
    const response = await axios({
      method: "post",
      url: `https://api.vimeo.com/me/videos`,
      headers: {
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        Authorization: `bearer ${process.env.REACT_APP_VIMEO_GLOBAL_ACCESS_TOKEN}`,
        "Content-Type": "application/json",
      },
      data: {
        upload: {
          approach: "tus",
          size: fileSize,
        },
      },
    });
    setValue("vimeo_id", response?.data.link.split("/")[response?.data?.link?.split("/")?.length - 1]);
    const upload = new tus.Upload(file, {
      endpoint: "https://api.vimeo.com/me/videos",
      uploadUrl: response.data.upload.upload_link,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      headers: {},
      onError: function (error) {
        setVideoUploadError("Failed because: " + error);
        setDisabledSubmitBtn(true);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        const percentage = Math.round((bytesUploaded / bytesTotal) * 100);
        setVideoUploadPersantage(percentage + "%");
      },
      onSuccess: function () {
        setVideoUploadError("");
        setDisabledSubmitBtn(false);
      },
    });
    upload.start();
    generateVideoThumbnail(file).then((res) => setVideoThumbnails(res));
  };

  const createIntroductionHandler = async (data: Lecture) => {
    setIsLoading(true);
    setVideoValidation("");

    if (!videoFile && lectureVideoType === "upload") {
      setVideoValidation(tCommon("required_message"));
      setIsLoading(false);
      return;
    }

    if (!introductionSection?.length) {
      setIsLoading(false);
      return;
    }

    const sectionId = introductionSection[0]?.id;
    const lecturePayload = {
      type: VIDEO,
      section_id: sectionId,
      display_name: { en: "Introduction", ar: "المقدمة" },
      ...data,
    } as Lecture;

    try {
      if (lectureVideoType === "with_id") {
        const token =
          data?.vimeo_account === "turkey"
            ? `bearer ${process.env.REACT_APP_VIMEO_TURKEY_ACCESS_TOKEN}`
            : `bearer ${process.env.REACT_APP_VIMEO_GLOBAL_ACCESS_TOKEN}`;

        const videoStatus = await _GetVimeoVideoStatus(data?.vimeo_id, token);

        if (videoStatus?.status !== "available") {
          setSubmitValidation(videoStatus?.error || tCommon("video_not_found"));
          return;
        }
      }

      await _CreateLecture(lecturePayload);
      refetchCourseSections();
      closePopup(false);
    } catch (error) {
      setSubmitValidation(getErrorMessage(error, tCommon("default_error_message")));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(createIntroductionHandler)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("add_introduction")}
          buttonTitle={tCommon("save_changes")}
          buttonState={disabledSubmitBtn}
          noteMessage={tCommon("intro_note_message")}
          closePopup={closePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            {introductionSection.length > 0 && (
              <Fragment>
                <InputsWrapper>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">{tCommon("select_lecture_type")}</InputLabel>
                    <Select
                      disabled
                      id="demo-simple-select"
                      IconComponent={ExpandMoreIcon}
                      value="video"
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="video">{tCommon("video")}</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                    <Controller
                      name="vimeo_account"
                      control={control}
                      defaultValue="global"
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="demo-simple-select"
                          IconComponent={ExpandMoreIcon}
                          MenuProps={MenuProps}
                          disabled={Boolean(lectureVideoType === "upload")}
                          {...register("vimeo_account")}
                        >
                          <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                          <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </InputsWrapper>
                <UploadVideoContainer>
                  <UploadTabsWrapper>
                    <UploadTab
                      onClick={() => setLectureVideoType("upload")}
                      isActiveTab={lectureVideoType === "upload"}
                    >
                      {tCommon("direct_upload")}
                    </UploadTab>
                    <UploadTab
                      onClick={() => setLectureVideoType("with_id")}
                      isActiveTab={lectureVideoType === "with_id"}
                    >
                      {tCommon("vimeo_id")}
                    </UploadTab>
                  </UploadTabsWrapper>
                  {lectureVideoType === "upload" && (
                    <DashboardContainer isVideo>
                      <div className="container">
                        {videoFile === null ? (
                          <UploadVideoInput>
                            <CenterDiv>
                              <UploadIcon />
                              <StyledPTag>{tCommon("drag_and_drop")}</StyledPTag>
                              <StyledPTag>{tCommon("video_types")}</StyledPTag>
                            </CenterDiv>
                            <FileUploader handleChange={vimeoVideoUploadhandler} name="file" types={videoFileTypes} />
                          </UploadVideoInput>
                        ) : (
                          <Fragment>
                            {videoUploadPersantage !== "100%" ? (
                              <VideoProgressContainer>
                                {videoThumbnails ? <StyledImage src={videoThumbnails} alt="" /> : <Place />}
                                <VideoProgressMessage>
                                  <StyledText>{tCommon("uploading_video")}</StyledText>
                                  <StyledProgress persantage={videoUploadPersantage}>
                                    <span />
                                  </StyledProgress>
                                  <StyledText>{videoUploadPersantage}</StyledText>
                                  <MessageContainer>
                                    <WaitToPlayIcon />
                                    <StyledText>{tCommon("video_upload_message")}</StyledText>
                                  </MessageContainer>
                                </VideoProgressMessage>
                              </VideoProgressContainer>
                            ) : (
                              <VideoProgressContainer>
                                {videoThumbnails ? <StyledImage src={videoThumbnails} alt="" /> : <Place />}
                                <VideoProgressMessage>
                                  <CheckIcon />
                                  <UploadedTitle>{tCommon("video_uploaded_title")}</UploadedTitle>
                                  <UploadedMessage>{tCommon("video_uploaded_message")}</UploadedMessage>
                                </VideoProgressMessage>
                              </VideoProgressContainer>
                            )}
                          </Fragment>
                        )}
                        {videoUploadError && <ErrorType center>{videoUploadError}</ErrorType>}
                      </div>
                      {videoValidation && videoFile === null && <ErrorType center>{videoValidation}</ErrorType>}
                    </DashboardContainer>
                  )}
                  {lectureVideoType === "with_id" && (
                    <DashboardContainer isVideo>
                      <Input
                        id="filled-basic"
                        variant="filled"
                        type="number"
                        className="noArrows"
                        label={tCommon("vimeo_video_id")}
                        {...register("vimeo_id", {
                          required: {
                            value: lectureVideoType === "with_id",
                            message: tCommon("required_message"),
                          },
                          valueAsNumber: true,
                        })}
                        onChange={() => setDisabledSubmitBtn(false)}
                        error={Boolean(errors.vimeo_id)}
                        helperText={errors.vimeo_id && errors.vimeo_id?.message}
                      />
                    </DashboardContainer>
                  )}
                </UploadVideoContainer>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">{tCommon("selecting_section")}</InputLabel>
                  <Select
                    disabled
                    id="demo-simple-select"
                    IconComponent={ExpandMoreIcon}
                    value="introductionSection"
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="introductionSection">{tCommon("introduction")}</MenuItem>
                  </Select>
                </FormControl>
              </Fragment>
            )}
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default AddIntroductionPopup;
