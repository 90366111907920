import {
  AdminImg,
  AdminSidebar,
  AdminSideContent,
  Line,
  ListIconWrapper,
  LogoWrapper,
  TopContent,
} from "./AdminSideBar.styled";
import { useTablesFilters } from "context/TablesFilters.context";
import { userInfo } from "context/UserInfo.context";
import { ListIcon } from "images/icons/ListIcon";
import { BaimsLogo } from "images/logos/BaimsLogo";
import { Dispatch, SetStateAction } from "react";
import { Link, useNavigate } from "react-router-dom";

type Props = {
  setOpenLogoutMenu: Dispatch<SetStateAction<boolean>>;
};

const AdminSideBar = ({ setOpenLogoutMenu }: Props) => {
  const navigate = useNavigate();
  const { userData } = userInfo();
  const { isSectionsSorted, setWorningSectionsSortPopup } = useTablesFilters();

  return (
    <AdminSidebar>
      <AdminSideContent>
        <TopContent>
          <Link to={"/"}>
            <LogoWrapper
              onClick={(e: React.MouseEvent) => {
                if (e.metaKey || e.ctrlKey) {
                  return;
                }
                e.preventDefault();
                if (isSectionsSorted) {
                  setWorningSectionsSortPopup(true);
                } else {
                  navigate("/");
                }
              }}
            >
              <BaimsLogo />
            </LogoWrapper>
          </Link>
          <Line />
          <Link to={"/"}>
            <ListIconWrapper
              onClick={(e: React.MouseEvent) => {
                if (e.metaKey || e.ctrlKey) {
                  return;
                }
                e.preventDefault();
                if (isSectionsSorted) {
                  setWorningSectionsSortPopup(true);
                } else {
                  navigate("/");
                }
              }}
            >
              <ListIcon />
            </ListIconWrapper>
          </Link>
        </TopContent>
        <AdminImg
          src={`${userData?.profile_picture.url}`}
          alt={`${userData?.name}`}
          onClick={() => {
            if (isSectionsSorted) {
              setWorningSectionsSortPopup(true);
            } else {
              setOpenLogoutMenu(true);
            }
          }}
        />
      </AdminSideContent>
    </AdminSidebar>
  );
};

export default AdminSideBar;
