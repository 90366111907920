import {
  ArrowWrapper,
  Dot,
  EditButton,
  EditIconWrapper,
  FileTypeWrapper,
  IntroDurationWrapper,
  LectureNameWrapper,
  LectureTypeName,
  SectionActiosnWrapper,
  SectionContainer,
  SectionErrorMessage,
  SectionInfo,
  SectionName,
  SectionWrapper,
  StyledTable,
  SwitchButton,
  SwitchContainer,
  Switcher,
  TableBody,
  TableBodyth,
  TableBodyTr,
  TableHead,
  TableHeadTh,
  TableHeadTr,
  TeacherName,
} from "./CourseSections.styled";
import { _UpdateLectureٍStatus } from "@Services/Lecture";
import { _UpdateSection } from "@Services/Sections";
import CircularProgress from "@mui/material/CircularProgress";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { useTablesFilters } from "context/TablesFilters.context";
import { TeachersDatasContext } from "context/Teachers.context";
import { userInfo } from "context/UserInfo.context";
import { ArrowDownIcon } from "images/icons/ArrowDownIcon";
import { DragDropIcon } from "images/icons/DragDropIcon";
import { EditIcon } from "images/icons/EditIcon";
import moment from "moment";
import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from "react";
import { Draggable, DraggableProvided, DraggableStateSnapshot, Droppable } from "react-beautiful-dnd";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { SectionsDataType } from "types/Courses";
import { getErrorMessage } from "utils/Forms";
import { getCourseName } from "utils/Strings";
import { secondsToHms } from "utils/Time";

type Props = {
  data: SectionsDataType;
  keyId: string;
  keyName: string;
  refProvid: DraggableProvided;
  snapShots: DraggableStateSnapshot;
  setEditSectionPopup: Dispatch<SetStateAction<boolean>>;
  setEditLecturePopup: Dispatch<SetStateAction<boolean>>;
  setSectionStatusChanged: Dispatch<SetStateAction<boolean>>;
};

const Section = ({
  data,
  keyId,
  keyName,
  refProvid,
  snapShots,
  setEditSectionPopup,
  setEditLecturePopup,
  setSectionStatusChanged,
}: Props) => {
  const { userData } = userInfo();
  const { courseSections, setSectionDetails, setLectureDetails, refetchCourseSections } =
    useContext(TeachersDatasContext);
  const { isSectionsSorted, setWorningSectionsSortPopup } = useTablesFilters();
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");

  const [isSectionStatusLoading, setIsSectionStatusLoading] = useState<boolean>(false);
  const [lectureLoadingStatus, setLectureLoadingStatus] = useState<Record<number, boolean>>({});
  const [changeStatusError, setChangeStatusError] = useState<{ type: string; error: string }>({
    type: "",
    error: "",
  });
  const [openFirstSection, setOpenFirstSection] = useState<boolean>(
    courseSections?.filter((section) => section?.is_introductory === false).length === 1 ? true : false
  );
  const getSectionData = courseSections?.filter((item) => item.id === Number(keyId));
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  // Change sections and lectures status on click
  const changeStatusHandler = (id: number, type: "section" | "lecture", currentStatus: boolean) => {
    if (!isRegularInstructor) {
      if (type === "section") {
        setIsSectionStatusLoading(true);
        _UpdateSection({ status: currentStatus ? 1 : 0 }, id)
          .then(() => {
            refetchCourseSections();
            setTimeout(() => {
              setSectionStatusChanged(true);
            }, 1000);
          })
          .catch((error) => {
            const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
            setChangeStatusError({ type: "section", error: errorMessage });
          })
          .finally(() => setIsSectionStatusLoading(false));
      }
      if (type === "lecture") {
        setLectureLoadingStatus((prev) => ({ ...prev, [id]: true }));
        _UpdateLectureٍStatus({ status: currentStatus ? 1 : 0 }, id)
          .then(() => {
            refetchCourseSections();
            setTimeout(() => {
              setSectionStatusChanged(true);
            }, 1000);
          })
          .catch((error) => {
            const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
            setChangeStatusError({ type: "lecture", error: errorMessage });
          })
          .finally(() => setLectureLoadingStatus((prev) => ({ ...prev, [id]: false })));
      }
    }
  };

  return (
    <SectionContainer ref={refProvid.innerRef} {...refProvid.draggableProps}>
      <SectionWrapper isOpened={openFirstSection} isDragging={snapShots.isDragging}>
        <SectionInfo>
          {!isRegularInstructor && (
            <div {...refProvid.dragHandleProps}>
              <DragDropIcon color={snapShots.isDragging} />
            </div>
          )}
          <SectionName>{keyName}</SectionName>
          <EditButton
            onClick={() => {
              if (isSectionsSorted) {
                setWorningSectionsSortPopup(true);
              } else {
                setEditSectionPopup(true);
                setSectionDetails(courseSections?.filter((item) => item?.id === Number(keyId)));
              }
            }}
            variant="lightseagule"
          >
            {tCommon("edit")}
          </EditButton>
        </SectionInfo>
        <SectionActiosnWrapper>
          <ArrowWrapper flex onClick={() => setOpenFirstSection(!openFirstSection)}>
            <SectionErrorMessage hasError={Boolean(changeStatusError?.type === "section")}>
              {changeStatusError?.error}
            </SectionErrorMessage>
          </ArrowWrapper>
          <SwitchContainer isDisabled={isRegularInstructor}>
            {isSectionStatusLoading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              <SwitchButton
                isDisabled={isRegularInstructor}
                isActive={getSectionData?.length > 0 && getSectionData[0]?.status}
                onClick={() => {
                  if (isSectionsSorted) {
                    setWorningSectionsSortPopup(true);
                  } else {
                    changeStatusHandler(Number(keyId), "section", !getSectionData[0]?.status);
                  }
                }}
              >
                <Switcher />
              </SwitchButton>
            )}
          </SwitchContainer>
          <ArrowWrapper onClick={() => setOpenFirstSection(!openFirstSection)}>
            <ArrowDownIcon />
          </ArrowWrapper>
        </SectionActiosnWrapper>
      </SectionWrapper>
      <Collapse isOpened={openFirstSection}>
        <StyledTable>
          {openFirstSection && (
            <Droppable droppableId={keyId} type="QUOTE" direction="vertical" isCombineEnabled={false}>
              {(dropProvided, dropSnapshot) => (
                <TableBody
                  isDraggingOver={dropSnapshot.isDraggingOver}
                  isDropDisabled={false}
                  isdraggingfrom={Boolean(dropSnapshot.draggingFromThisWith)}
                  {...dropProvided.droppableProps}
                >
                  <TableHead ref={dropProvided.innerRef}>
                    <TableHeadTr>
                      <TableHeadTh>{tCommon("lecture_name")}</TableHeadTh>
                      <TableHeadTh>{tCommon("date_added")}</TableHeadTh>
                      <TableHeadTh>{tCommon("visibility")}</TableHeadTh>
                      <TableHeadTh></TableHeadTh>
                    </TableHeadTr>
                    {data?.columns[keyId]?.map((quote, index) => (
                      <Draggable key={quote.id} draggableId={String(quote.id)} index={index}>
                        {(dragProvided, dragSnapshot) => (
                          <TableBodyTr
                            isDragging={dragSnapshot.isDragging}
                            isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            key={quote.id}
                          >
                            <TableBodyth>
                              <LectureNameWrapper>
                                {!isRegularInstructor && (
                                  <span {...dragProvided.dragHandleProps}>
                                    <DragDropIcon color={dragSnapshot.isDragging} />
                                  </span>
                                )}
                                <FileTypeWrapper>
                                  <TeacherName>{getCourseName(quote?.display_name, language)}</TeacherName>
                                  {quote?.media_type === "video" ? (
                                    <Fragment>
                                      {quote?.media?.extra_data?.duration_in_seconds === 0 ? (
                                        <TeacherName>{tCommon("video_optamizing")}</TeacherName>
                                      ) : (
                                        <IntroDurationWrapper>
                                          <LectureTypeName>{tCommon("video")}</LectureTypeName>
                                          <Dot />
                                          <LectureTypeName>
                                            {secondsToHms(quote?.media?.extra_data?.duration_in_seconds)}
                                          </LectureTypeName>
                                        </IntroDurationWrapper>
                                      )}
                                    </Fragment>
                                  ) : quote?.media_type === "file" ? (
                                    <IntroDurationWrapper>
                                      <LectureTypeName>{tCommon("file")}</LectureTypeName>
                                    </IntroDurationWrapper>
                                  ) : (
                                    <></>
                                  )}
                                </FileTypeWrapper>
                              </LectureNameWrapper>
                            </TableBodyth>
                            <TableBodyth>
                              <TeacherName>{moment(quote.created_at).format("DD/MM/YYYY")}</TeacherName>
                            </TableBodyth>
                            <TableBodyth>
                              <SwitchContainer isDisabled={isRegularInstructor}>
                                {lectureLoadingStatus[quote.id] ? (
                                  <CircularProgress color="inherit" size={24} />
                                ) : (
                                  <SwitchButton
                                    isDisabled={isRegularInstructor}
                                    isActive={quote.status}
                                    onClick={() => {
                                      if (isSectionsSorted) {
                                        setWorningSectionsSortPopup(true);
                                      } else {
                                        changeStatusHandler(quote?.id, "lecture", !quote?.status);
                                      }
                                    }}
                                  >
                                    <Switcher />
                                  </SwitchButton>
                                )}
                              </SwitchContainer>
                              <SectionErrorMessage hasError={Boolean(changeStatusError?.type === "lecture")}>
                                {changeStatusError?.error}
                              </SectionErrorMessage>
                            </TableBodyth>
                            <TableBodyth>
                              <EditIconWrapper
                                onClick={() => {
                                  if (isSectionsSorted) {
                                    setWorningSectionsSortPopup(true);
                                  } else {
                                    setEditLecturePopup(true);
                                    setLectureDetails(quote);
                                  }
                                }}
                              >
                                <EditIcon color />
                              </EditIconWrapper>
                            </TableBodyth>
                          </TableBodyTr>
                        )}
                      </Draggable>
                    ))}
                    {dropProvided.placeholder}
                  </TableHead>
                </TableBody>
              )}
            </Droppable>
          )}
        </StyledTable>
      </Collapse>
    </SectionContainer>
  );
};

export default Section;
