import AddCoursePopup from "@Components/Popups/Course/AddCoursePopup";
import CourseAccessPopup from "@Components/Popups/Course/CourseAccessPopup";
import SortingWorningPopup from "@Components/Popups/SortingWorningPopup";
import DeleteSubjectPopup from "@Components/Popups/Subject/DeleteSubjectPopup";
import EditSubjectPopup from "@Components/Popups/Subject/EditSubjectPopup";
import EditSubjectPricePopup from "@Components/Popups/Subject/EditSubjectPricePopup";
import { PagesContainer } from "@Components/RegularHeader/RegularHeader.styled";
import SubjectOverviewSkeleton from "@Components/Skeletons/SubjectOverviewSkeleton";
import AnalyticsBoxs from "@Components/SubjectPage/AnalyticsBoxs";
import OverviewHeader from "@Components/SubjectPage/OverviewHeader";
import OverviewPrice from "@Components/SubjectPage/OverviewPrice";
import { useTablesFilters } from "context/TablesFilters.context";
import { TeachersDatasContext } from "context/Teachers.context";
import { useEffect, useState, Fragment, useContext } from "react";
import { useParams } from "react-router-dom";
import { Course } from "types/Courses";

const SubjectOverview = () => {
  const { subjectid } = useParams();
  const {
    isSubjectDetailsFetching,
    setSubjectId,
    addCoursePopup,
    setAddCoursePopup,
    editSubjectPrice,
    setEditSubjectPrice,
    courseAccessPopup,
    setCourseAccessPopup,
  } = useContext(TeachersDatasContext);
  const { worningSectionsSortPopup } = useTablesFilters();

  const [addNewCourseData, setAddNewCourseData] = useState<Course>(null);
  const [editSubjectPopup, setEditSubjectPopup] = useState<boolean>(false);
  const [deleteSubjectPopup, setDeleteSubjectPopup] = useState<boolean>(false);

  useEffect(() => {
    if (subjectid) {
      setSubjectId(Number(subjectid));
    }
  }, [subjectid]);

  return (
    <PagesContainer>
      <OverviewHeader openPopup={setEditSubjectPopup} />
      {!isSubjectDetailsFetching ? (
        <Fragment>
          <AnalyticsBoxs />
          <OverviewPrice openEditSubjectPrice={setEditSubjectPrice} />
        </Fragment>
      ) : (
        <SubjectOverviewSkeleton />
      )}
      <EditSubjectPopup
        isOpen={editSubjectPopup}
        closePopup={setEditSubjectPopup}
        openDeletePopup={setDeleteSubjectPopup}
      />
      <EditSubjectPricePopup isOpen={editSubjectPrice} closePopup={setEditSubjectPrice} />
      <AddCoursePopup
        isOpen={addCoursePopup}
        closePopup={setAddCoursePopup}
        setAddNewCourseData={setAddNewCourseData}
      />
      <CourseAccessPopup
        isOpen={courseAccessPopup}
        closePopup={setCourseAccessPopup}
        newCourseData={addNewCourseData}
      />
      <DeleteSubjectPopup isOpen={deleteSubjectPopup} closePopup={setDeleteSubjectPopup} subjectid={subjectid} />
      <SortingWorningPopup isOpen={worningSectionsSortPopup} />
    </PagesContainer>
  );
};

export default SubjectOverview;
