import {
  Discount,
  FullSubject,
  NewPrice,
  OldPrice,
  OverviewPriceContainer,
  OverviewPriceDetails,
  OverviewPriceTitle,
  PriceContainer,
  PriceDetails,
  PriceEdit,
  PriceEditButton,
  PriceEditButtonContainer,
  PriceEditContainer,
  TotalPrice,
} from "./OverviewPrice.styled";
import { FreePrice } from "@Components/CoursePage/CourseSections/CourseSections.styled";
import { PRO_INSTRUCTOR, ADMIN, PERCENTAGE_TAB, NO_DISCOUNT_TAB, FINAL_PRICE_TAB } from "constants/index";
import { useTablesFilters } from "context/TablesFilters.context";
import { TeachersDatasContext } from "context/Teachers.context";
import { userInfo } from "context/UserInfo.context";
import { Dispatch, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import { calculateDiscount } from "utils/Forms";
import { convertDiscountValueToNumber } from "utils/Strings";

type Props = {
  openEditSubjectPrice: Dispatch<SetStateAction<boolean>>;
};

const OverviewPrice = ({ openEditSubjectPrice }: Props) => {
  const { userData } = userInfo();
  const { subjectDetails } = useContext(TeachersDatasContext);
  const { isSectionsSorted, setWorningSectionsSortPopup } = useTablesFilters();
  const { t: tCommon } = useTranslation("common");

  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);
  const discountValue = convertDiscountValueToNumber(subjectDetails?.offer?.discount_value);
  const isSubjectPriceFree = Boolean(
    (subjectDetails?.offer?.offer_type === PERCENTAGE_TAB &&
      subjectDetails?.price - (subjectDetails?.price * discountValue) / 100 === 0) ||
      (subjectDetails?.offer?.offer_type === FINAL_PRICE_TAB &&
        convertDiscountValueToNumber(subjectDetails?.offer?.discount_value) === 0)
  );

  return (
    <OverviewPriceContainer>
      <OverviewPriceTitle>{tCommon("price")}</OverviewPriceTitle>
      <OverviewPriceDetails>
        <PriceDetails>
          <TotalPrice>{tCommon("total_cost")}</TotalPrice>
          {subjectDetails?.price === 0 ? (
            <FreePrice>{tCommon("free")}</FreePrice>
          ) : (
            <>
              {subjectDetails?.offer && subjectDetails?.offer?.offer_type !== NO_DISCOUNT_TAB ? (
                <>
                  {isSubjectPriceFree ? (
                    <FreePrice>{tCommon("free")}</FreePrice>
                  ) : (
                    <PriceContainer>
                      <NewPrice>
                        {subjectDetails?.offer?.offer_type === PERCENTAGE_TAB ? (
                          <>
                            {subjectDetails?.price - (subjectDetails?.price * discountValue) / 100}{" "}
                            {subjectDetails?.currency}
                          </>
                        ) : (
                          <>
                            {convertDiscountValueToNumber(subjectDetails?.offer?.discount_value)}{" "}
                            {subjectDetails?.currency}
                          </>
                        )}
                      </NewPrice>
                      <OldPrice>
                        {subjectDetails?.price} {subjectDetails?.currency}
                      </OldPrice>
                    </PriceContainer>
                  )}
                </>
              ) : (
                <NewPrice>
                  {subjectDetails?.price} {subjectDetails?.currency}
                </NewPrice>
              )}
            </>
          )}
        </PriceDetails>
        <PriceEdit>
          {subjectDetails?.offer && !isSubjectPriceFree && subjectDetails?.offer?.offer_type !== NO_DISCOUNT_TAB && (
            <PriceEditContainer>
              <FullSubject>{tCommon("full_subject")}</FullSubject>
              <Discount>
                {tCommon("discount")}{" "}
                {subjectDetails?.offer?.offer_type === PERCENTAGE_TAB
                  ? discountValue
                  : calculateDiscount(
                      subjectDetails?.price,
                      convertDiscountValueToNumber(subjectDetails?.offer?.discount_value)
                    )}{" "}
                %
              </Discount>
            </PriceEditContainer>
          )}
          {!isRegularInstructor && (
            <PriceEditButtonContainer
              disabled={subjectDetails?.price === 0}
              isDisabled={subjectDetails?.price === 0}
              onClick={() => {
                if (isSectionsSorted) {
                  setWorningSectionsSortPopup(true);
                } else {
                  openEditSubjectPrice(true);
                }
              }}
            >
              <PriceEditButton>{tCommon("editـpricing")}</PriceEditButton>
            </PriceEditButtonContainer>
          )}
        </PriceEdit>
      </OverviewPriceDetails>
    </OverviewPriceContainer>
  );
};

export default OverviewPrice;
