import Rodal from "rodal";
import styled from "styled-components";

export const CourseAccessPopupWrapper = styled(Rodal)`
  .rodal-mask {
    background: rgba(0, 0, 0, 0.3);
    z-index: 300 !important;
  }
  .rodal-dialog {
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 486px !important;
    max-width: 486px !important;
    min-height: 255 !important;
    max-height: 255px !important;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 16px !important;
    z-index: 400 !important;
  }
  .rodal-close {
    display: none !important;
  }
`;

export const CourseAccessPopupTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.h5};
  font-weight: ${({ theme }) => theme.fontWeights.font700};
  color: ${({ theme }) => theme.colors.nero};
  margin-bottom: 12px;
`;

export const CourseAccessPopupContent = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.body16};
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  color: ${({ theme }) => theme.colors.gray2};
  margin-bottom: 24px;
`;

export const CourseAccessPopupButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  & > button {
    flex: 1;
    padding: 10px 16px;
    font-size: ${({ theme }) => theme.fontSizes.body16};
    font-weight: ${({ theme }) => theme.fontWeights.font600};
  }
`;
