import Axios from "@Configs/Axios";
import { EditSubjectPrice, Subject } from "types/Subjects";

// Node.js Endpoints
export const _GetTeacherSubjects = (id: string | undefined, category_id?: any) =>
  Axios.post(`/v1/subjects/instructors/${id}`, {
    limit: 100,
    skip: 0,
    ...(category_id !== "" && { category_id }),
  }).then(({ data }) => data.data);

// PHP Endpoints
export const _CreateSubject = (data: Subject) => Axios.post(`${process.env.REACT_APP_URL_PHP_API}/v1/subjects`, data);

export const _UpdateSubject = (subjectId: number, data: Subject) =>
  Axios.post(`${process.env.REACT_APP_URL_PHP_API}/v1/subjects/${subjectId}`, data);

export const _DeleteSubject = (id: string | undefined) =>
  Axios.delete(`${process.env.REACT_APP_URL_PHP_API}/v1/subjects/${id}`);

export const _GetSubjectDetails = (id: number | undefined) =>
  Axios.get(`${process.env.REACT_APP_URL_PHP_API}/v1/subjects/${id}`).then(({ data }) => data.data);

export const _UpdateSubjectOffer = (data: EditSubjectPrice) =>
  Axios.post(`${process.env.REACT_APP_URL_PHP_API}/v1/offers`, data);
