import BreadcrumbButton from "@Components/Breadcrumbs/BreadcrumbButton";
import BreadcrumbsMenu from "@Components/Breadcrumbs/BreadcrumbMenu";
import NoResultsMessage from "@Components/NoResultsMessage";
import AddSubjectPopup from "@Components/Popups/Subject/AddSubjectPopup";
import SubjectsHeader from "@Components/RegularHeader";
import { PagesContainer } from "@Components/RegularHeader/RegularHeader.styled";
import TableLoadingSkeleton from "@Components/Skeletons/TableLoadingSkeleton";
import TableSkeleton from "@Components/Skeletons/TableSkeleton";
import TableLayout from "@Components/TableLayout";
import SubjectsColumns from "@Components/TablesColumns/SubjectsColumns";
import { FiltersContainer } from "@Components/TeachersList/TeachersFilters/TeachersFilters.styled";
import { _GetCategories } from "@Services/Common";
import { _CreateSubject, _GetTeacherSubjects } from "@Services/Subjects";
import { _GetTeacherProfile } from "@Services/Teachers";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
} from "@tanstack/react-table";
import { FILTER_TYPE_SUBJECTS } from "constants/index";
import { useTablesFilters } from "context/TablesFilters.context";
import { TeachersDatasContext } from "context/Teachers.context";
import moment from "moment";
import { useState, useEffect, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { TeacherSubjects } from "types/Subjects";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};

const SubjectsList = () => {
  const { teacherid } = useParams();
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");
  const [addSubject, setAddSubject] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const { setTeacherId } = useContext(TeachersDatasContext);
  const { subjectCategoryIdFilter, sortingSubjects, setSortingSubjects } = useTablesFilters();
  const {
    data: teacherSubjectList,
    refetch: refetchTeacherSubjects,
    isFetching,
  } = useQuery(["teachersubjects", { language }], () => _GetTeacherSubjects(teacherid, subjectCategoryIdFilter));

  const [activeSubjects, setActiveSubjects] = useState([]);
  const [inActiveSubjects, setInActiveSubjects] = useState([]);
  const [data, setData] = useState<TeacherSubjects[]>(teacherSubjectList);
  const columns = SubjectsColumns();

  useEffect(() => {
    if (teacherSubjectList) {
      setActiveSubjects(teacherSubjectList?.filter((item) => item.is_active));
      setInActiveSubjects(teacherSubjectList?.filter((item) => !item.is_active));
    }
  }, [teacherSubjectList]);

  useEffect(() => {
    if (teacherSubjectList) {
      setData(activeSubjects?.concat(inActiveSubjects));
    }
  }, [activeSubjects, inActiveSubjects]);

  useEffect(() => {
    refetchTeacherSubjects();
    setData(activeSubjects?.concat(inActiveSubjects));
  }, [subjectCategoryIdFilter]);

  useEffect(() => {
    if (teacherid) {
      setTeacherId(Number(teacherid));
    }
  }, [teacherid]);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting: sortingSubjects,
    },
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSortingSubjects,
    sortDescFirst: true,
    enableSortingRemoval: true,
    enableMultiSort: false,
    maxMultiSortColCount: 6,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  useEffect(() => {
    if (data?.length === 0 && !subjectCategoryIdFilter) {
      setTimeout(() => {
        setShowImage(true);
      }, 700);
    }
  }, [data, subjectCategoryIdFilter]);

  useEffect(() => {
    table.setPageSize(1000);
  }, []);

  return (
    <Fragment>
      <PagesContainer>
        <SubjectsHeader
          title={tCommon("subjects")}
          lastUpdate={`${tCommon("last_time_updated")} ${moment(teacherSubjectList?.updated_at)
            .locale(language)
            .format("DD MMM YYYY")}`}
          buttonTitle={tCommon("add_subject")}
          openPopup={setAddSubject}
        />
        {data ? (
          <Fragment>
            {data?.length === 0 && !subjectCategoryIdFilter ? (
              <></>
            ) : (
              <FiltersContainer hasMarginBottom>
                <BreadcrumbButton
                  filterType={FILTER_TYPE_SUBJECTS}
                  isOpen={isDropdownOpen}
                  setIsOpen={setIsDropdownOpen}
                >
                  <BreadcrumbsMenu
                    filterType={FILTER_TYPE_SUBJECTS}
                    isDropdownOpen={isDropdownOpen}
                    setIsDropdownOpen={setIsDropdownOpen}
                  />
                </BreadcrumbButton>
              </FiltersContainer>
            )}
            {!isFetching ? (
              <Fragment>
                {data?.length === 0 && !subjectCategoryIdFilter ? (
                  <Fragment>{showImage && <NoResultsMessage message={tCommon("no_subject_added")} />}</Fragment>
                ) : (
                  <TableLayout table={table} link="overview" />
                )}
              </Fragment>
            ) : (
              <TableLoadingSkeleton />
            )}
          </Fragment>
        ) : (
          <TableSkeleton />
        )}
        <AddSubjectPopup isOpen={addSubject} closePopup={setAddSubject} subjectsRefetch={refetchTeacherSubjects} />
      </PagesContainer>
    </Fragment>
  );
};

export default SubjectsList;
