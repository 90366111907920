import {
  CourseAccessPopupButtonsWrapper,
  CourseAccessPopupContent,
  CourseAccessPopupTitle,
  CourseAccessPopupWrapper,
} from "./CourseAccessPopup.styled";
import { ErrorType } from "@Components/Popups/PopupsLayout/PopupsLayout.styled";
import { CommonButton } from "@Components/common/CommonButton";
import { _CreateCourse } from "@Services/Courses";
import { _GetVimeoFolderIdStatus, _GetVimeoFolderVideosListStatus } from "@Services/Lecture";
import { CircularProgress } from "@mui/material";
import { TeachersDatasContext } from "context/Teachers.context";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Course } from "types/Courses";
import { getErrorMessage } from "utils/Forms";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  newCourseData: Course;
}

function CourseAccessPopup({ isOpen, closePopup, newCourseData }: Props) {
  const { refetchCoursesList, refetchSubjectDetails, setAddCoursePopup, setVimeoVideosIdsStillNotReady } =
    useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  const [hasGrantLoading, setHasGrantLoading] = useState<boolean>(false);
  const [hasNoGrantLoading, setHasNoGrantLoading] = useState<boolean>(false);
  const [submitValidation, setSubmitValidation] = useState<string>("");

  const createCourseHandler = async (data: Course, accessType: boolean) => {
    setSubmitValidation("");

    if (!data?.vimeo_folder_id) {
      return await handleCourseCreation(data, accessType);
    }

    const vimeoToken =
      data?.vimeo_account === "turkey"
        ? `bearer ${process.env.REACT_APP_VIMEO_TURKEY_ACCESS_TOKEN}`
        : `bearer ${process.env.REACT_APP_VIMEO_GLOBAL_ACCESS_TOKEN}`;

    try {
      const folderStatus = await _GetVimeoFolderIdStatus(data?.vimeo_folder_id, vimeoToken);

      if (!folderStatus?.uri) {
        setSubmitValidation(folderStatus?.error || tCommon("video_not_found"));
        return;
      }

      await handleCourseCreation(data, accessType);

      const videoListStatus = await _GetVimeoFolderVideosListStatus(data?.vimeo_folder_id, vimeoToken);
      const vimeoVideoId = videoListStatus?.data
        ?.reduce((max, video) => (video.duration > max.duration ? video : max))
        .uri.split("/")
        .pop();

      setVimeoVideosIdsStillNotReady((prev) => [
        ...prev,
        { videoId: Number(vimeoVideoId), account: data?.vimeo_account },
      ]);
    } catch (error) {
      const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
      setSubmitValidation(errorMessage);
    } finally {
      setHasGrantLoading(false);
      setHasNoGrantLoading(false);
    }
  };

  const handleCourseCreation = async (filteredData: Course, accessType: boolean) => {
    try {
      await _CreateCourse({ apply_free_subscription: accessType, ...filteredData } as Course);
      closePopup(false);
      setAddCoursePopup(false);
      refetchCoursesList();
      setTimeout(() => {
        refetchSubjectDetails();
      }, 3000);
    } catch (error) {
      setSubmitValidation(getErrorMessage(error, tCommon("default_error_message")));
    } finally {
      setHasGrantLoading(false);
      setHasNoGrantLoading(false);
    }
  };

  return (
    <CourseAccessPopupWrapper visible={isOpen} onClose={() => closePopup(false)}>
      <CourseAccessPopupTitle>{tCommon("grant_subscription_title")}</CourseAccessPopupTitle>
      <CourseAccessPopupContent>{tCommon("grant_subscription_message")}</CourseAccessPopupContent>
      {submitValidation && <ErrorType>{submitValidation}</ErrorType>}
      <CourseAccessPopupButtonsWrapper>
        <CommonButton
          variant="lightseagule"
          onClick={() => {
            setHasNoGrantLoading(true);
            createCourseHandler(newCourseData, false);
          }}
        >
          {hasNoGrantLoading ? <CircularProgress color="inherit" size={24} /> : tCommon("no")}
        </CommonButton>
        <CommonButton
          variant="lightseagule"
          onClick={() => {
            setHasGrantLoading(true);
            createCourseHandler(newCourseData, true);
          }}
        >
          {hasGrantLoading ? <CircularProgress color="inherit" size={24} /> : tCommon("grant_subscription")}
        </CommonButton>
      </CourseAccessPopupButtonsWrapper>
    </CourseAccessPopupWrapper>
  );
}

export default CourseAccessPopup;
