import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";
import { PopupSectionTitle } from "@Components/Popups/Lecture/AddLecturePopup/AddLecturePopup.styled";
import PopupsLayout from "@Components/Popups/PopupsLayout";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import { _UpdateCourse } from "@Services/Courses";
import { _GetVimeoFolderIdStatus, _GetVimeoFolderVideosListStatus } from "@Services/Lecture";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { TeachersDatasContext } from "context/Teachers.context";
import { userInfo } from "context/UserInfo.context";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Course } from "types/Courses";
import { getErrorMessage, removeEmptyProperties } from "utils/Forms";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  openDeletePopup: Dispatch<SetStateAction<boolean>>;
}

const EditCoursePopup = ({ isOpen, closePopup, openDeletePopup }: Props) => {
  const { userData } = userInfo();
  const {
    courseId,
    refetchCoursesList,
    courseDetails,
    refetchCourseDetails,
    refetchCourseSections,
    refetchSubjectDetails,
    setVimeoVideosIdsStillNotReady,
  } = useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitValidation, setSubmitValidation] = useState<string>("");
  const [disablebutton, setDisablebutton] = useState<boolean>(true);
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { errors, isDirty },
  } = useForm<Course>();

  useEffect(() => {
    if (courseDetails) {
      reset({
        display_name: {
          en: courseDetails?.display_name?.en,
          ar: courseDetails?.display_name?.ar,
        },
        content: {
          en: courseDetails?.description?.en,
          ar: courseDetails?.description?.ar,
        },
        price: courseDetails?.price,
        link: courseDetails?.link,
        name: courseDetails?.name,
        vimeo_folder_id: courseDetails?.vimeo_folder_id,
        vimeo_account: courseDetails?.vimeo_folder_id ? courseDetails?.vimeo_account : "",
        status: courseDetails?.status?.toString(),
      });
    }
    setSubmitValidation("");
    setDisablebutton(true);
  }, [courseDetails, isOpen]);

  const updateCourseHandler = async (data: Course) => {
    setIsLoading(true);
    setSubmitValidation("");

    const { status, ...restData } = data;

    const filteredData: Course = removeEmptyProperties(restData);

    if (!courseDetails) {
      setIsLoading(false);
      return;
    }

    const watchedVimeoFolderId = watch("vimeo_folder_id");
    const existingVimeoFolderId = courseDetails?.vimeo_folder_id;

    if (watchedVimeoFolderId && existingVimeoFolderId !== watchedVimeoFolderId) {
      const vimeoToken =
        filteredData.vimeo_account === "turkey"
          ? `bearer ${process.env.REACT_APP_VIMEO_TURKEY_ACCESS_TOKEN}`
          : `bearer ${process.env.REACT_APP_VIMEO_GLOBAL_ACCESS_TOKEN}`;

      try {
        const folderStatus = await _GetVimeoFolderIdStatus(filteredData.vimeo_folder_id, vimeoToken);

        if (!folderStatus?.uri) {
          setSubmitValidation(folderStatus?.error || tCommon("video_not_found"));
          return;
        }

        await handleEditCourse(filteredData, status);

        const videoListStatus = await _GetVimeoFolderVideosListStatus(filteredData.vimeo_folder_id, vimeoToken);
        const vimeoVideoId = videoListStatus?.data
          ?.reduce((max, video) => (video.duration > max.duration ? video : max))
          .uri.split("/")
          .pop();

        setVimeoVideosIdsStillNotReady((prev) => [
          ...prev,
          { videoId: Number(vimeoVideoId), account: filteredData.vimeo_account },
        ]);
      } catch (error) {
        const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
        setSubmitValidation(errorMessage);
      } finally {
        setIsLoading(false);
      }
    } else {
      return await handleEditCourse(filteredData, status);
    }
  };

  const handleEditCourse = async (filteredData: Course, status: string | number) => {
    try {
      await _UpdateCourse(courseId, {
        status: status === "true" ? 1 : 0,
        ...filteredData,
      } as Course);
      refetchCourseDetails();
      refetchCoursesList();
      refetchCourseSections();
      closePopup(false);
      setTimeout(() => {
        refetchSubjectDetails();
      }, 3000);
    } catch (error) {
      setSubmitValidation(getErrorMessage(error, tCommon("default_error_message")));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(updateCourseHandler)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("edit_course")}
          buttonTitle={tCommon("save_changes")}
          buttonState={Boolean(!isDirty && disablebutton)}
          closePopup={closePopup}
          noteMessage={tCommon("edit_item_message")}
          showDeleteButton={!isRegularInstructor}
          deleteTitle={tCommon("delete_course")}
          openDelete={openDeletePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            <PopupSectionTitle noBorder>{tCommon("main_details")}</PopupSectionTitle>
            <MultibleLanguagesInputs
              isCourse
              data={courseDetails}
              isOpen={isOpen}
              errors={errors}
              setValue={setValue}
              register={register}
              setDisablebutton={setDisablebutton}
            />
            {!isRegularInstructor && (
              <>
                <PopupSectionTitle>{tCommon("pricing_title")}</PopupSectionTitle>
                <Input
                  id="filled-basic"
                  variant="filled"
                  type="number"
                  className="noArrows"
                  label={tCommon("course_cost")}
                  {...register("price", {
                    required: tCommon("required_message"),
                    valueAsNumber: true,
                  })}
                  inputProps={{
                    step: "1",
                    onWheel: (e) => e.currentTarget.blur(),
                    onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.replace(/^0+(\d)/, "$1").replace(/[^0-9]/g, "");
                    },
                  }}
                  error={Boolean(errors.price)}
                  helperText={errors.price && errors.price?.message}
                />
              </>
            )}
            <PopupSectionTitle>{tCommon("internal_details")}</PopupSectionTitle>
            <Input
              id="filled-basic"
              variant="filled"
              label={tCommon("link")}
              {...register("link", {
                pattern: {
                  // This pattern for [ No spaces , No Arabic letters , No links ]
                  value: /^(?!.*(?:http:\/\/|https:\/\/))^(?!.*\.[^\s]+)(?=[^\u0600-\u06FF\s]*$).*$/,
                  message: tCommon("course_link_validation"),
                },
              })}
              error={Boolean(errors.link)}
              helperText={errors.link && errors.link?.message}
            />
            {!isRegularInstructor && (
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("internal_name")}
                {...register("name", {
                  required: tCommon("required_message"),
                })}
                error={Boolean(errors.name)}
                helperText={errors.name && errors.name?.message}
              />
            )}
            <PopupSectionTitle>{tCommon("add_content")}</PopupSectionTitle>
            <InputsWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                type="number"
                className="noArrows"
                label={tCommon("vimeo_folder_id")}
                {...register("vimeo_folder_id", {
                  valueAsNumber: true,
                })}
                inputProps={{
                  step: "1",
                  onWheel: (e) => e.currentTarget.blur(),
                  onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.replace(/^0+(\d)/, "$1").replace(/[^0-9]/g, "");
                  },
                }}
              />
              <FormControl variant="filled" fullWidth>
                <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                <Controller
                  name="vimeo_account"
                  control={control}
                  defaultValue={courseDetails ? courseDetails?.vimeo_account : ""}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="demo-simple-select"
                      IconComponent={ExpandMoreIcon}
                      MenuProps={MenuProps}
                      {...register("vimeo_account")}
                    >
                      <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                      <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </InputsWrapper>
            {!isRegularInstructor && (
              <>
                <PopupSectionTitle>{tCommon("status")}</PopupSectionTitle>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">{tCommon("state")}</InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    defaultValue={courseDetails ? courseDetails?.status?.toString() : ""}
                    render={({ field }) => (
                      <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                        <MenuItem value="true">{tCommon("active")}</MenuItem>
                        <MenuItem value="false">{tCommon("inactive")}</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </>
            )}
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default EditCoursePopup;
