import { EditWrapper, FreePriceTag } from "../CourseSections/CourseSections.styled";
import {
  CourseAddedDate,
  CourseHeaderContainer,
  CourseHeaderDetails,
  CourseOldPrice,
  CoursePriceWraper,
  CourseTitle,
} from "./CourseHeader.styled";
import RegularHeaderSkeleton from "@Components/Skeletons/RegularHeaderSkeleton";
import { EditButtonText, EditSubjectBtn } from "@Components/SubjectPage/OverviewHeader/OverviewHeader.styled";
import { useTablesFilters } from "context/TablesFilters.context";
import { TeachersDatasContext } from "context/Teachers.context";
import moment from "moment";
import { Dispatch, Fragment, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import { getCourseName } from "utils/Strings";

type Props = {
  openPopup: Dispatch<SetStateAction<boolean>>;
};

const CourseHeader = ({ openPopup }: Props) => {
  const { courseDetails, isCourseDetailsLoading } = useContext(TeachersDatasContext);
  const { isSectionsSorted, setWorningSectionsSortPopup } = useTablesFilters();
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");

  return (
    <Fragment>
      {!isCourseDetailsLoading ? (
        <CourseHeaderContainer>
          <CourseHeaderDetails>
            <CourseTitle>{getCourseName(courseDetails?.display_name, language)}</CourseTitle>
            {courseDetails?.price === 0 ? (
              <FreePriceTag>{tCommon("free")}</FreePriceTag>
            ) : (
              <CoursePriceWraper>
                <CourseOldPrice>
                  {courseDetails?.price} {courseDetails?.currency.toLocaleUpperCase()}
                </CourseOldPrice>
              </CoursePriceWraper>
            )}
            {courseDetails?.created_at !== null && (
              <CourseAddedDate>{`${tCommon("date_added")} ${moment(courseDetails?.created_at).format(
                "DD/MM/YYYY"
              )}`}</CourseAddedDate>
            )}
          </CourseHeaderDetails>
          <EditSubjectBtn
            onClick={() => {
              if (isSectionsSorted) {
                setWorningSectionsSortPopup(true);
              } else {
                openPopup(true);
              }
            }}
          >
            <EditButtonText>{tCommon("edit_course")}</EditButtonText>
          </EditSubjectBtn>
        </CourseHeaderContainer>
      ) : (
        <RegularHeaderSkeleton />
      )}
    </Fragment>
  );
};

export default CourseHeader;
