import {
  UploadFileInput,
  DashboardContainer,
  DeleteFile,
  CenterDiv,
  FileInputWrapper,
  FileNameWrapper,
  MessageContainer,
  VideoProgressMessage,
  VideoProgressContainer,
  Place,
  StyledImage,
  StyledProgress,
  StyledPTag,
  StyledText,
  UploadedMessage,
  UploadedTitle,
  UploadTab,
  UploadTabsWrapper,
  UploadVideoContainer,
  UploadVideoInput,
} from "./AddLecturePopup.styled";
import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";
import PopupsLayout from "@Components/Popups/PopupsLayout";
import { ErrorType } from "@Components/Popups/PopupsLayout/PopupsLayout.styled";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import { _CreateLecture, _GetVimeoVideoStatus } from "@Services/Lecture";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { FILE, VIDEO, docFileTypes, videoFileTypes } from "constants/Types";
import { TeachersDatasContext } from "context/Teachers.context";
import { BoldCloseIcon } from "images/icons/BoldCloseIcon";
import CheckIcon from "images/icons/CheckIcon";
import FileIcon from "images/icons/FileIcon";
import UploadIcon from "images/icons/UploadIcon";
import UploadPdf from "images/icons/UploadPdf";
import WaitToPlayIcon from "images/icons/WaitToPlayIcon";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as tus from "tus-js-client";
import { CourseSectionsType } from "types/Courses";
import { Lecture } from "types/Lecture";
import { getErrorMessage } from "utils/Forms";
import { getCourseName, isEmptyValue } from "utils/Strings";
import { generateVideoThumbnail } from "utils/Video";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
}

const AddLecturePopup = ({ isOpen, closePopup }: Props) => {
  const { refetchCourseSections, courseSections } = useContext(TeachersDatasContext);
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");

  // Form State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitValidation, setSubmitValidation] = useState<string>("");
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState<boolean>(true);
  const [lectureType, setLectureType] = useState<"video" | "file" | "">();
  const [sectionsList, setSectionsList] = useState<CourseSectionsType[]>();

  // File State
  const [docFile, setDocFile] = useState<File>(null);
  const [fileValidation, setFileValidation] = useState<string>("");

  // Video State
  const [videoFile, setVideoFile] = useState<File>(null);
  const [lectureVideoType, setLectureVideoType] = useState<"with_id" | "upload">();
  const [videoThumbnails, setVideoThumbnails] = useState<string>("");
  const [videoUploadPersantage, setVideoUploadPersantage] = useState<string>("0%");
  const [videoUploadError, setVideoUploadError] = useState<string>("");
  const [videoValidation, setVideoValidation] = useState<string>("");

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { isDirty, errors },
  } = useForm<Lecture>();

  useEffect(() => {
    if (!isOpen) {
      reset();
      setSubmitValidation("");
      setDisabledSubmitBtn(true);
      setLectureType("");
      setLectureVideoType("upload");
      setDocFile(null);
      setVideoFile(null);
      setVideoThumbnails("");
      setVideoUploadPersantage("0%");
      setVideoUploadError("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (lectureType) {
      reset();
      setDocFile(null);
      setVideoFile(null);
    }
  }, [lectureType]);

  // To ulpoad video to vimeo api
  const vimeoVideoUploadhandler = async (eventObject) => {
    const file = eventObject;
    setVideoFile(file);
    const fileSize = file.size.toString();
    const response = await axios({
      method: "post",
      url: `https://api.vimeo.com/me/videos`,
      headers: {
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        Authorization: `bearer ${process.env.REACT_APP_VIMEO_GLOBAL_ACCESS_TOKEN}`,
        "Content-Type": "application/json",
      },
      data: {
        upload: {
          approach: "tus",
          size: fileSize,
        },
      },
    });
    setValue("vimeo_id", response?.data.link.split("/")[response?.data?.link?.split("/")?.length - 1]);
    const upload = new tus.Upload(file, {
      endpoint: "https://api.vimeo.com/me/videos",
      uploadUrl: response.data.upload.upload_link,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      headers: {},
      onError: function (error) {
        setVideoUploadError("Failed because: " + error);
        setDisabledSubmitBtn(true);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        const percentage = Math.round((bytesUploaded / bytesTotal) * 100);
        setVideoUploadPersantage(percentage + "%");
      },
      onSuccess: function () {
        setVideoUploadError("");
        setDisabledSubmitBtn(false);
      },
    });
    upload.start();
    generateVideoThumbnail(file).then((res) => setVideoThumbnails(res));
  };

  // To filter interoduction section from sections array
  useEffect(() => {
    if (courseSections) {
      setSectionsList(courseSections?.filter((section) => section?.is_introductory === false));
    }
  }, [courseSections]);

  const createLectureHandler = (data: Lecture) => {
    setIsLoading(true);
    setFileValidation("");
    setVideoValidation("");

    if (lectureType === FILE && docFile === null) {
      setFileValidation(tCommon("required_message"));
      setIsLoading(false);
      return;
    }

    if (lectureType === VIDEO && videoFile === null && lectureVideoType === "upload") {
      setVideoValidation(tCommon("required_message"));
      setIsLoading(false);
      return;
    }

    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (isEmptyValue(value)) return;
      formData.append(key, typeof value === "object" && value !== null ? JSON.stringify(value) : value);
    });

    if (lectureType) {
      formData.append("type", lectureType);
    }

    if (docFile && lectureType === FILE) {
      formData.append(FILE, docFile);
    }

    if (lectureVideoType === "with_id") {
      _GetVimeoVideoStatus(
        data?.vimeo_id,
        data?.vimeo_account === "turkey"
          ? `bearer ${process.env.REACT_APP_VIMEO_TURKEY_ACCESS_TOKEN}`
          : `bearer ${process.env.REACT_APP_VIMEO_GLOBAL_ACCESS_TOKEN}`
      )
        .then((value) => {
          if (value?.status === "available") {
            _CreateLecture(formData as Lecture)
              .then(() => {
                refetchCourseSections();
                closePopup(false);
              })
              .catch((error) => {
                const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
                setSubmitValidation(errorMessage);
              });
          } else {
            setSubmitValidation(value?.error || tCommon("video_not_found"));
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      _CreateLecture(formData as Lecture)
        .then(() => {
          refetchCourseSections();
          closePopup(false);
        })
        .catch((error) => {
          const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
          setSubmitValidation(errorMessage);
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(createLectureHandler)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("add_new_lecture")}
          buttonTitle={tCommon("add_lecture")}
          buttonState={
            !isDirty || Boolean(lectureType === "video" && lectureVideoType === "upload" && disabledSubmitBtn)
          }
          closePopup={closePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            <InputsWrapper>
              <FormControl variant="filled" fullWidth>
                <InputLabel id="demo-simple-select-filled-label">{tCommon("select_lecture_type")}</InputLabel>
                <Select
                  id="demo-simple-select"
                  IconComponent={ExpandMoreIcon}
                  value={lectureType ? lectureType : ""}
                  displayEmpty
                  MenuProps={MenuProps}
                  onChange={(e) => setLectureType(e.target.value as "video" | "file")}
                >
                  <MenuItem value="" style={{ visibility: "hidden" }} />
                  <MenuItem value={FILE}>{tCommon("Note_pdf_word")}</MenuItem>
                  <MenuItem value={VIDEO}>{tCommon("video")}</MenuItem>
                </Select>
              </FormControl>
              {lectureType === VIDEO && (
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                  <Controller
                    name="vimeo_account"
                    control={control}
                    defaultValue={lectureVideoType === "upload" ? "global" : ""}
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="demo-simple-select"
                        IconComponent={ExpandMoreIcon}
                        MenuProps={MenuProps}
                        disabled={Boolean(lectureVideoType === "upload")}
                        {...register("vimeo_account")}
                      >
                        <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                        <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              )}
            </InputsWrapper>
            {lectureType === VIDEO && (
              <UploadVideoContainer>
                <UploadTabsWrapper>
                  <UploadTab onClick={() => setLectureVideoType("upload")} isActiveTab={lectureVideoType === "upload"}>
                    {tCommon("direct_upload")}
                  </UploadTab>
                  <UploadTab
                    onClick={() => setLectureVideoType("with_id")}
                    isActiveTab={lectureVideoType === "with_id"}
                  >
                    {tCommon("vimeo_id")}
                  </UploadTab>
                </UploadTabsWrapper>
                {lectureVideoType === "upload" && (
                  <DashboardContainer isVideo>
                    <div className="container">
                      {videoFile === null ? (
                        <UploadVideoInput>
                          <CenterDiv>
                            <UploadIcon />
                            <StyledPTag>{tCommon("drag_and_drop")}</StyledPTag>
                            <StyledPTag>{tCommon("video_types")}</StyledPTag>
                          </CenterDiv>
                          <FileUploader handleChange={vimeoVideoUploadhandler} name="file" types={videoFileTypes} />
                        </UploadVideoInput>
                      ) : (
                        <Fragment>
                          {videoUploadPersantage !== "100%" ? (
                            <VideoProgressContainer>
                              {videoThumbnails ? <StyledImage src={videoThumbnails} alt="" /> : <Place />}
                              <VideoProgressMessage>
                                <StyledText>{tCommon("uploading_video")}</StyledText>
                                <StyledProgress persantage={videoUploadPersantage}>
                                  <span />
                                </StyledProgress>
                                <StyledText>{videoUploadPersantage}</StyledText>
                                <MessageContainer>
                                  <WaitToPlayIcon />
                                  <StyledText>{tCommon("video_upload_message")}</StyledText>
                                </MessageContainer>
                              </VideoProgressMessage>
                            </VideoProgressContainer>
                          ) : (
                            <VideoProgressContainer>
                              {videoThumbnails ? <StyledImage src={videoThumbnails} alt="" /> : <Place />}
                              <VideoProgressMessage>
                                <CheckIcon />
                                <UploadedTitle>{tCommon("video_uploaded_title")}</UploadedTitle>
                                <UploadedMessage>{tCommon("video_uploaded_message")}</UploadedMessage>
                              </VideoProgressMessage>
                            </VideoProgressContainer>
                          )}
                        </Fragment>
                      )}
                      {videoUploadError && <ErrorType center>{videoUploadError}</ErrorType>}
                    </div>
                    {videoValidation && videoFile === null && <ErrorType center>{videoValidation}</ErrorType>}
                  </DashboardContainer>
                )}
                {lectureVideoType === "with_id" && (
                  <DashboardContainer isVideo>
                    <Input
                      id="filled-basic"
                      label="Vimeo Video ID"
                      variant="filled"
                      {...register("vimeo_id", {
                        required: tCommon("required_message"),
                        valueAsNumber: true,
                      })}
                      inputProps={{
                        step: "1",
                        onWheel: (e) => e.currentTarget.blur(),
                        onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                          e.target.value = e.target.value.replace(/^0+(\d)/, "$1").replace(/[^0-9]/g, "");
                        },
                      }}
                      error={Boolean(errors.vimeo_id)}
                      helperText={errors.vimeo_id && errors.vimeo_id?.message}
                    />
                  </DashboardContainer>
                )}
              </UploadVideoContainer>
            )}
            {lectureType === FILE && (
              <DashboardContainer>
                <div className="container">
                  {docFile === null ? (
                    <UploadFileInput>
                      <CenterDiv>
                        <StyledPTag>{tCommon("drop_here")}</StyledPTag>
                        <UploadPdf />
                      </CenterDiv>
                      <FileUploader handleChange={(e) => setDocFile(e)} name="file" types={docFileTypes} />
                    </UploadFileInput>
                  ) : (
                    <Fragment>
                      <UploadFileInput>
                        <FileInputWrapper>
                          <FileNameWrapper>
                            <FileIcon />
                            <StyledPTag textEllipsis noMargin>
                              {docFile?.name}
                            </StyledPTag>
                          </FileNameWrapper>
                          <DeleteFile type="button" onClick={() => setDocFile(null)}>
                            <BoldCloseIcon />
                          </DeleteFile>
                        </FileInputWrapper>
                      </UploadFileInput>
                    </Fragment>
                  )}
                </div>
                {fileValidation && docFile === null && <ErrorType center>{fileValidation}</ErrorType>}
              </DashboardContainer>
            )}
            {lectureType && (
              <Fragment>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">{tCommon("selecting_section")}</InputLabel>
                  <Select
                    id="demo-simple-select"
                    IconComponent={ExpandMoreIcon}
                    MenuProps={MenuProps}
                    {...register("section_id")}
                  >
                    {sectionsList?.map((section, index) => (
                      <MenuItem key={index} value={section?.id}>
                        {getCourseName(section?.display_name, language)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <MultibleLanguagesInputs
                  islecture
                  isOpen={isOpen}
                  errors={errors}
                  setValue={setValue}
                  register={register}
                />
              </Fragment>
            )}
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default AddLecturePopup;
