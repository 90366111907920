import { ErrorType, StyledRodal } from "@Components/Popups/PopupsLayout/PopupsLayout.styled";
import {
  CancleButton,
  DeleteButton,
  DeletePopupAlert,
  DeletePopupButtons,
  DeletePopupHeader,
  DeletePopupWrapper,
} from "@Components/Popups/Subject/DeleteSubjectPopup/DeleteSubjectPopup.styled";
import { _DeleteCourse } from "@Services/Courses";
import { _DeleteSection } from "@Services/Sections";
import CircularProgress from "@mui/material/CircularProgress";
import { TeachersDatasContext } from "context/Teachers.context";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getErrorMessage } from "utils/Forms";

type Props = {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
};

const DeleteSectionPopup = ({ isOpen, closePopup }: Props) => {
  const { refetchCourseSections, sectionDetails } = useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitValidation, setSubmitValidation] = useState<string>("");

  useEffect(() => {
    if (!isOpen) {
      setSubmitValidation("");
    }
  }, [isOpen]);

  const deleteSectionHandler = (sectionId: number) => {
    setIsLoading(true);
    _DeleteSection(sectionId)
      .then(() => {
        refetchCourseSections();
        closePopup(false);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
        setSubmitValidation(errorMessage);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <StyledRodal isDelete visible={isOpen} onClose={() => closePopup(false)}>
      <DeletePopupWrapper>
        <DeletePopupHeader>{tCommon("delete_section_title")}</DeletePopupHeader>
        <DeletePopupAlert hasError={submitValidation !== ""}>{tCommon("delete_section_message")}</DeletePopupAlert>
        {submitValidation && <ErrorType>{submitValidation}</ErrorType>}
        <DeletePopupButtons>
          <CancleButton variant="lightseagule" onClick={() => closePopup(false)}>
            {tCommon("cancel")}
          </CancleButton>
          <DeleteButton
            variant="default"
            disabled={isLoading}
            onClick={() => deleteSectionHandler(sectionDetails[0]?.id)}
          >
            {isLoading ? <CircularProgress color="inherit" size={24} /> : tCommon("delete")}
          </DeleteButton>
        </DeletePopupButtons>
      </DeletePopupWrapper>
    </StyledRodal>
  );
};

export default DeleteSectionPopup;
