import Axios from "@Configs/Axios";

// Node.js Endpoints
export const _GetCategories = (categoryId?: number) =>
  Axios.get(`/v1/onboarding-steps/categories${categoryId ? "/" + categoryId : ""}`).then(({ data }) => data.data);

export const _GetUserProfile = () => Axios.get(`/v1/users/profile`).then(({ data }) => data.data);

// PHP Endpoints
export const _GetCurrencies = () =>
  Axios.get(`${process.env.REACT_APP_URL_PHP_API}/v1/currencies`).then(({ data }) => data.data);
