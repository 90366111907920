import CourseContent from "@Components/CoursePage/CourseContent";
import CourseHeader from "@Components/CoursePage/CourseHeader";
import CourseNumbers from "@Components/CoursePage/CourseNumbers";
import CourseSections from "@Components/CoursePage/CourseSections";
import AddCoursePopup from "@Components/Popups/Course/AddCoursePopup";
import CourseAccessPopup from "@Components/Popups/Course/CourseAccessPopup";
import DeleteCoursePopup from "@Components/Popups/Course/DeleteCoursePopup";
import EditCoursePopup from "@Components/Popups/Course/EditCoursePopup";
import AddIntroductionPopup from "@Components/Popups/Lecture/AddIntroductionPopup";
import AddLecturePopup from "@Components/Popups/Lecture/AddLecturePopup";
import DeleteLecturePopup from "@Components/Popups/Lecture/DeleteLecturePopup";
import EditIntroductionPopup from "@Components/Popups/Lecture/EditIntroductionPopup";
import EditLecturePopup from "@Components/Popups/Lecture/EditLecturePopup";
import AddSectionPopup from "@Components/Popups/Section/AddSectionPopup";
import DeleteSectionPopup from "@Components/Popups/Section/DeleteSectionPopup";
import EditSectionPopup from "@Components/Popups/Section/EditSectionPopup";
import EmptySectionAlertPopup from "@Components/Popups/Section/EmptySectionAlertPopup";
import SortingWorningPopup from "@Components/Popups/SortingWorningPopup";
import EditSubjectPricePopup from "@Components/Popups/Subject/EditSubjectPricePopup";
import { PagesContainer } from "@Components/RegularHeader/RegularHeader.styled";
import { useTablesFilters } from "context/TablesFilters.context";
import { TeachersDatasContext } from "context/Teachers.context";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Course } from "types/Courses";

const SubjectCourse = () => {
  const { courseid } = useParams();
  const { worningSectionsSortPopup } = useTablesFilters();
  const {
    setCourseId,
    addCoursePopup,
    setAddCoursePopup,
    editSubjectPrice,
    setEditSubjectPrice,
    setSubjectId,
    courseAccessPopup,
    setCourseAccessPopup,
    subjectDetails,
  } = useContext(TeachersDatasContext);
  const {
    i18n: { language },
  } = useTranslation();

  const [addNewCourseData, setAddNewCourseData] = useState<Course>(null);
  const [editCoursePopup, setEditCoursePopup] = useState<boolean>(false);
  const [deleteCoursePopup, setDeleteCoursePopup] = useState<boolean>(false);
  const [addSectionPopup, setAddSectionPopup] = useState<boolean>(false);
  const [addLecturePopup, setAddLecturePopup] = useState<boolean>(false);
  const [editLecturePopup, setEditLecturePopup] = useState<boolean>(false);
  const [emptySectionPopup, setEmptySectionPopup] = useState<boolean>(false);
  const [editSectionPopup, setEditSectionPopup] = useState<boolean>(false);
  const [deleteSectionPopup, setDeleteSectionPopup] = useState<boolean>(false);
  const [deleteLecturePopup, setDeleteLecturePopup] = useState<boolean>(false);
  const [editIntroductionPopup, setEditIntroductionPopup] = useState<boolean>(false);
  const [addIntroductionPopup, setAddIntroductionPopup] = useState<boolean>(false);

  useEffect(() => {
    if (courseid) {
      setCourseId(Number(courseid));
    }
  }, [courseid, language]);

  useEffect(() => {
    if (subjectDetails) {
      setSubjectId(subjectDetails?.id);
    }
  }, [subjectDetails]);

  return (
    <PagesContainer>
      <CourseHeader openPopup={setEditCoursePopup} />
      <CourseNumbers />
      <CourseContent openSectionPopup={setAddSectionPopup} openLecturePopup={setAddLecturePopup} />
      <CourseSections
        setEditSectionPopup={setEditSectionPopup}
        setEditLecturePopup={setEditLecturePopup}
        setEditIntroductionPopup={setEditIntroductionPopup}
        setAddIntroductionPopup={setAddIntroductionPopup}
      />
      <AddCoursePopup
        isOpen={addCoursePopup}
        closePopup={setAddCoursePopup}
        setAddNewCourseData={setAddNewCourseData}
      />
      <EditCoursePopup
        isOpen={editCoursePopup}
        closePopup={setEditCoursePopup}
        openDeletePopup={setDeleteCoursePopup}
      />
      <DeleteCoursePopup isOpen={deleteCoursePopup} closePopup={setDeleteCoursePopup} courseid={courseid} />
      <AddSectionPopup isOpen={addSectionPopup} closePopup={setAddSectionPopup} openAlertPopup={setEmptySectionPopup} />
      <EditSectionPopup
        isOpen={editSectionPopup}
        closePopup={setEditSectionPopup}
        openDeletePopup={setDeleteSectionPopup}
      />
      <DeleteSectionPopup isOpen={deleteSectionPopup} closePopup={setDeleteSectionPopup} />
      <AddLecturePopup isOpen={addLecturePopup} closePopup={setAddLecturePopup} />
      <EditLecturePopup
        isOpen={editLecturePopup}
        closePopup={setEditLecturePopup}
        openDeletePopup={setDeleteLecturePopup}
      />
      <AddIntroductionPopup isOpen={addIntroductionPopup} closePopup={setAddIntroductionPopup} />
      <EditIntroductionPopup isOpen={editIntroductionPopup} closePopup={setEditIntroductionPopup} />
      <DeleteLecturePopup isOpen={deleteLecturePopup} closePopup={setDeleteLecturePopup} />
      <EmptySectionAlertPopup isOpen={emptySectionPopup} closePopup={setEmptySectionPopup} />
      <EditSubjectPricePopup isOpen={editSubjectPrice} closePopup={setEditSubjectPrice} />
      <CourseAccessPopup
        isOpen={courseAccessPopup}
        closePopup={setCourseAccessPopup}
        newCourseData={addNewCourseData}
      />
      <SortingWorningPopup isOpen={worningSectionsSortPopup} />
    </PagesContainer>
  );
};

export default SubjectCourse;
