import { userInfo } from "./UserInfo.context";
import { _GetUserProfile } from "@Services/Common";
import { _GetCourseDetails, _GetCourseSections, _GetCoursesList } from "@Services/Courses";
import { _GetSubjectDetails } from "@Services/Subjects";
import { _GetTeacherProfile } from "@Services/Teachers";
import { TEACHER_ID, COURSE_ID, SUBJECT_ID, USER_DEVICE_ID } from "constants/index";
import { createContext, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { CourseLecturesType, CourseSectionsType } from "types/Courses";
import { VimeoVideosCheckTypes } from "types/Sections";
import { TeachersContext } from "types/Teachers";
import { getCourseName } from "utils/Strings";
import { v4 as uuidv4 } from "uuid";

const initialState: TeachersContext = {
  teacherId: null,
  setTeacherId: () => null,
  subjectId: null,
  setSubjectId: () => null,
  courseId: null,
  setCourseId: () => null,
  addCoursePopup: false,
  setAddCoursePopup: () => null,
  editSubjectPrice: false,
  setEditSubjectPrice: () => null,
  courseAccessPopup: false,
  setCourseAccessPopup: () => null,
  teacherProfile: null,
  refetchProfile: () => null,
  profileFetching: () => null,
  courseSections: null,
  isCourseSectionsLoading: null,
  refetchCourseSections: () => null,
  isCourseSectionsFetching: null,
  subjectDetails: null,
  isSubjectDetailsloading: null,
  refetchSubjectDetails: () => null,
  isSubjectDetailsFetching: null,
  courseDetails: null,
  isCourseDetailsLoading: null,
  refetchCourseDetails: () => null,
  coursesList: null,
  isCoursesListLoading: null,
  refetchCoursesList: () => null,
  newData: null,
  setNewData: () => null,
  sectionSortSaveBtn: null,
  setSectionSortSaveBtn: () => null,
  sortedCollection: null,
  setSortedCollection: () => null,
  initDataHandler: () => null,
  cancleSavingSectionSort: null,
  setCancleSavingSectionSort: () => null,
  sectionDetails: null,
  setSectionDetails: () => null,
  lectureDetails: null,
  setLectureDetails: () => null,
  vimeoVideosIdsStillNotReady: null,
  setVimeoVideosIdsStillNotReady: () => null,
};

export const TeachersDatasContext = createContext<TeachersContext>(initialState);

type Props = {
  children?: React.PropsWithChildren<any>;
};

export default function PortalProvider({ children }: Props): ReactElement {
  const {
    i18n: { language },
  } = useTranslation();
  const { teacherSidebar, subjectSidebar } = userInfo();
  const [addCoursePopup, setAddCoursePopup] = useState(false);
  const [sectionSortSaveBtn, setSectionSortSaveBtn] = useState(false);
  const [editSubjectPrice, setEditSubjectPrice] = useState(false);
  const [courseAccessPopup, setCourseAccessPopup] = useState(false);
  const [cancleSavingSectionSort, setCancleSavingSectionSort] = useState(false);
  const [sectionDetails, setSectionDetails] = useState<CourseSectionsType[]>();
  const [lectureDetails, setLectureDetails] = useState<CourseLecturesType>();
  const [vimeoVideosIdsStillNotReady, setVimeoVideosIdsStillNotReady] = useState<VimeoVideosCheckTypes[]>([]);
  const location = useLocation();
  const path = location?.pathname.split("/")[location?.pathname.split("/").length - 1];
  const [newData, setNewData] = useState(null);
  const [sortedCollection, setSortedCollection] = useState(null);

  // LOCAL STORGE
  useEffect(() => {
    if (typeof window !== "undefined" && !localStorage.getItem(USER_DEVICE_ID)) {
      localStorage.setItem(USER_DEVICE_ID, uuidv4());
    }
  }, []);

  const [teacherId, setTeacherId] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.localStorage.getItem(TEACHER_ID);
      return localData !== null ? JSON.parse(localData) : "";
    }
  });

  const [subjectId, setSubjectId] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.localStorage.getItem(SUBJECT_ID);
      return localData !== null ? JSON.parse(localData) : "";
    }
  });

  const [courseId, setCourseId] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.localStorage.getItem(COURSE_ID);
      return localData !== null ? JSON.parse(localData) : "";
    }
  });

  const {
    data: teacherProfile,
    refetch: refetchProfile,
    isFetching: profileFetching,
  } = useQuery(["TeacherProfile", { language, teacherId }], () => _GetTeacherProfile(teacherId), {
    enabled: Boolean(teacherSidebar),
  });

  const {
    data: subjectDetails,
    isLoading: isSubjectDetailsloading,
    refetch: refetchSubjectDetails,
    isFetching: isSubjectDetailsFetching,
  } = useQuery(["subjectDetails", { language, subjectId }], () => _GetSubjectDetails(subjectId), {
    enabled: Boolean(subjectSidebar && subjectId),
  });

  const {
    data: courseDetails,
    isLoading: isCourseDetailsLoading,
    refetch: refetchCourseDetails,
  } = useQuery(["courseDetails", { language, courseId }], () => _GetCourseDetails(courseId), {
    enabled: Boolean(subjectSidebar && courseId && path === "course"),
  });

  const {
    data: coursesList,
    isLoading: isCoursesListLoading,
    refetch: refetchCoursesList,
  } = useQuery(["courses", { language, subjectId }], () => _GetCoursesList(subjectId), {
    enabled: Boolean(subjectSidebar && subjectId),
  });

  const {
    data: courseSections,
    isLoading: isCourseSectionsLoading,
    refetch: refetchCourseSections,
    isFetching: isCourseSectionsFetching,
  } = useQuery(["courseid", { language, courseId }], () => _GetCourseSections(courseId), {
    enabled: Boolean(subjectSidebar && courseId && path === "course"),
    onSuccess: (data) => {
      initDataHandler(data);
      setSortedCollection(courseSections);
    },
  });

  const initDataHandler = (sectionsData: CourseSectionsType[]) => {
    const initData = {
      columns: {},
      ordered: [],
    };
    const obj = {};
    setNewData(initData);

    const sectionsWithoutIntro = sectionsData?.filter((section) => section?.is_introductory === false);

    for (let i = 0; i < sectionsWithoutIntro.length; i++) {
      obj[String(sectionsWithoutIntro[i].id)] = sectionsWithoutIntro[i].lectures;
      initData.ordered.push([
        getCourseName(sectionsWithoutIntro[i]?.display_name, language),
        String(sectionsWithoutIntro[i].id),
      ]);
      Object.assign(initData.columns, obj);
      if (i == sectionsWithoutIntro.length - 1) {
        setNewData(initData);
      }
    }
  };

  useEffect(() => {
    if (cancleSavingSectionSort) {
      refetchCourseSections();
      setSectionSortSaveBtn(false);
    }
  }, [cancleSavingSectionSort, courseId]);

  useEffect(() => {
    if (courseSections) {
      setCancleSavingSectionSort(false);
    }
  }, [courseSections]);

  // LOCAL STORGE
  useEffect(() => {
    window.localStorage.setItem(TEACHER_ID, JSON.stringify(teacherId));
  }, [teacherId]);

  useEffect(() => {
    window.localStorage.setItem(SUBJECT_ID, JSON.stringify(subjectId));
  }, [subjectId]);

  useEffect(() => {
    window.localStorage.setItem(COURSE_ID, JSON.stringify(courseId));
  }, [courseId]);

  return (
    <TeachersDatasContext.Provider
      value={{
        teacherId,
        setTeacherId,
        subjectId,
        setSubjectId,
        courseId,
        setCourseId,
        addCoursePopup,
        setAddCoursePopup,
        teacherProfile,
        refetchProfile,
        profileFetching,
        courseSections,
        isCourseSectionsLoading,
        refetchCourseSections,
        isCourseSectionsFetching,
        subjectDetails,
        isSubjectDetailsloading,
        refetchSubjectDetails,
        isSubjectDetailsFetching,
        courseDetails,
        isCourseDetailsLoading,
        refetchCourseDetails,
        coursesList,
        isCoursesListLoading,
        refetchCoursesList,
        newData,
        setNewData,
        sectionSortSaveBtn,
        setSectionSortSaveBtn,
        sortedCollection,
        setSortedCollection,
        initDataHandler,
        editSubjectPrice,
        setEditSubjectPrice,
        courseAccessPopup,
        setCourseAccessPopup,
        cancleSavingSectionSort,
        setCancleSavingSectionSort,
        sectionDetails,
        setSectionDetails,
        lectureDetails,
        setLectureDetails,
        vimeoVideosIdsStillNotReady,
        setVimeoVideosIdsStillNotReady,
      }}
    >
      {children}
    </TeachersDatasContext.Provider>
  );
}
