import Axios from "@Configs/Axios";
import { Section } from "types/Sections";

export const _CreateSection = (data: Section) => Axios.post(`${process.env.REACT_APP_URL_PHP_API}/v1/sections`, data);

export const _UpdateSection = (data: Section, sectionId: number) =>
  Axios.put(`${process.env.REACT_APP_URL_PHP_API}/v1/sections/${sectionId}`, data);

export const _DeleteSection = (id: number) => Axios.delete(`${process.env.REACT_APP_URL_PHP_API}/v1/sections/${id}`);

export const _SortingSectionsAndLectures = (CourseId: number, data: any) =>
  Axios.put(`${process.env.REACT_APP_URL_PHP_API}/v1/courses/${CourseId}/sections/sort`, data);
