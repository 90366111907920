import EditProfilePopup from "@Components/Popups/Profile/EditProfilePopup";
import RegularHeader from "@Components/RegularHeader";
import { PagesContainer } from "@Components/RegularHeader/RegularHeader.styled";
import ProfileSkeleton from "@Components/Skeletons/ProfileSkeleton";
import RegularHeaderSkeleton from "@Components/Skeletons/RegularHeaderSkeleton";
import ProfileDetails from "@Components/TeacherProfile";
import {
  BioContainer,
  BioDescription,
  BioTitle,
  PersonalContent,
  ProfileBio,
  ProfileContentContainer,
} from "@Components/TeacherProfile/TeacherProfile.styled";
import { _GetTeacherProfile } from "@Services/Teachers";
import { ADMIN } from "constants/index";
import { TeachersDatasContext } from "context/Teachers.context";
import { userInfo } from "context/UserInfo.context";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const TeacherProfile = () => {
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation();
  const { teacherid } = useParams();
  const [editProfile, setEditProfile] = useState(false);
  const { userData } = userInfo();
  const { teacherProfile, profileFetching, setTeacherId } = useContext(TeachersDatasContext);

  useEffect(() => {
    if (!userData?.roles.includes(ADMIN)) {
      setTeacherId(Number(teacherid));
    }
  }, [userData, teacherid]);

  return (
    <PagesContainer>
      {!profileFetching ? (
        <RegularHeader
          icon
          title={tCommon("profile")}
          lastUpdate={`${tCommon("last_time_updated")} ${moment(teacherProfile?.updated_at)
            .locale(language)
            .format("DD MMM YYYY")}`}
          buttonTitle={tCommon("edit")}
          openPopup={setEditProfile}
        />
      ) : (
        <RegularHeaderSkeleton />
      )}
      {!profileFetching ? (
        <Fragment>
          <ProfileDetails openPopup={setEditProfile} title={tCommon("bio")} basic={teacherProfile?.bio} />
          <PersonalContent>
            <BioContainer>
              <ProfileDetails
                openPopup={setEditProfile}
                title={tCommon("phone_number")}
                basic={teacherProfile?.phone}
              />
              {/* <ProfileDetails openPopup={setEditProfile} title={tCommon("iban")} basic="" /> */}
              <ProfileContentContainer>
                <ProfileBio>
                  <BioTitle>{tCommon("remaining_balance")}</BioTitle>
                  {teacherProfile?.instructor_balances.length !== 0 ? (
                    <Fragment>
                      {teacherProfile?.instructor_balances.map((balances, index) => (
                        <BioDescription
                          key={index}
                          capitalize
                        >{`${balances.remaining_balance} ${balances.currency}`}</BioDescription>
                      ))}
                    </Fragment>
                  ) : (
                    <BioDescription>0</BioDescription>
                  )}
                </ProfileBio>
              </ProfileContentContainer>
            </BioContainer>
            <BioContainer>
              <ProfileDetails openPopup={setEditProfile} title={tCommon("email")} basic={teacherProfile?.email} />
              {/* <ProfileDetails openPopup={setEditProfile} title={tCommon("bank")} basic="" /> */}
              <ProfileContentContainer>
                <ProfileBio>
                  <BioTitle>{tCommon("total_income")}</BioTitle>
                  {teacherProfile?.instructor_balances.length !== 0 ? (
                    <Fragment>
                      {teacherProfile?.instructor_balances.map((balances, index) => (
                        <BioDescription
                          key={index}
                          capitalize
                        >{`${balances.total_balance} ${balances.currency}`}</BioDescription>
                      ))}
                    </Fragment>
                  ) : (
                    <BioDescription>0</BioDescription>
                  )}
                </ProfileBio>
              </ProfileContentContainer>
            </BioContainer>
          </PersonalContent>
        </Fragment>
      ) : (
        <ProfileSkeleton />
      )}
      <EditProfilePopup isOpen={editProfile} closePopup={setEditProfile} />
    </PagesContainer>
  );
};

export default TeacherProfile;
