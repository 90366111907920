import {
  AddCourseButton,
  ChapterState,
  ChaptersWrapper,
  ChapterTitle,
  Courses,
  CoursesContainer,
  CoursesIcons,
  CoursesInfoWrapper,
  CoursesList,
  CoursesSaveSort,
  CoursesTitle,
  EmptyCourses,
  OverView,
  OverViewTitle,
  SubjectSidebar,
  SubjectSidebarContent,
  SubjectTitle,
} from "./CoursesSideBar.styled";
import SubjectCoursesSkeleton from "@Components/Skeletons/SubjectCoursesSkeleton";
import TitleSkeleton from "@Components/Skeletons/TitleSkeleton";
import { _SortingCourses } from "@Services/Courses";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { useTablesFilters } from "context/TablesFilters.context";
import { TeachersDatasContext } from "context/Teachers.context";
import { userInfo } from "context/UserInfo.context";
import { DragDropIcon } from "images/icons/DragDropIcon";
import { OverViewIcon } from "images/icons/OverViewIcon";
import { PlusIcon } from "images/icons/PlusIcon";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CoursesListTypes } from "types/Courses";
import { getSubjectName } from "utils/Strings";

const CoursesSideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = userInfo();
  const { setCancleSavingSectionSort } = useContext(TeachersDatasContext);
  const {
    subjectId,
    setAddCoursePopup,
    subjectDetails,
    isSubjectDetailsloading,
    coursesList,
    setCourseId,
    isCoursesListLoading,
  } = useContext(TeachersDatasContext);
  const {
    isSectionsSorted,
    setIsSectionsSorted,
    setWorningSectionsSortPopup,
    setWorningSortType,
    worningSectionsSortPopup,
    worningSortType,
    setSubjectTitleHeight,
  } = useTablesFilters();
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");

  const scrollingToTopRef = useRef<HTMLDivElement>(null);
  const subjectNameHeightRef = useRef<HTMLDivElement>(null);

  const [sortingActionButtons, setSortingActionButtons] = useState<boolean>(false);
  const [sortedCourses, setSortedCourses] = useState<CoursesListTypes[]>(coursesList);
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  const overviewPath = location?.pathname.split("/")[location?.pathname.split("/").length - 1] === "overview";
  const coursePath = location?.pathname.split("/")[1];

  const onDragEnd = (result: DropResult) => {
    if (result) {
      setSortingActionButtons(true);
    }
    const { source, destination } = result;
    if (!destination) return;
    const items = Array.from(sortedCourses);
    const [newOrder] = items.splice(source.index, 1);
    items.splice(destination.index, 0, newOrder);
    setSortedCourses(items);
    setIsSectionsSorted(true);
    setWorningSortType("courses");
  };

  const saveSortingHandler = () => {
    _SortingCourses({
      subject_id: subjectId,
      courses: sortedCourses?.map((course: CoursesListTypes, index: number) => ({
        id: course.id,
        order_id: index + 1,
      })),
    });
    setSortingActionButtons(false);
    setIsSectionsSorted(false);
    setWorningSectionsSortPopup(false);
  };

  const cancleSortingHandler = () => {
    setSortedCourses(coursesList);
    setSortingActionButtons(false);
    setIsSectionsSorted(false);
    setWorningSectionsSortPopup(false);
  };

  useEffect(() => {
    if (coursesList) {
      setSortedCourses(coursesList);
    }
  }, [coursesList]);

  // To scrolling to top and show the alert messge
  useEffect(() => {
    if (isSectionsSorted && worningSectionsSortPopup && worningSortType === "courses") {
      scrollingToTopRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [isSectionsSorted, worningSectionsSortPopup, worningSortType]);

  // To change top possotion for the alert message depend on subjecrt name height
  useEffect(() => {
    if (subjectNameHeightRef.current && subjectNameHeightRef.current.clientHeight > 36) {
      setSubjectTitleHeight(true);
    } else {
      setSubjectTitleHeight(false);
    }
  }, [subjectNameHeightRef.current]);

  return (
    <SubjectSidebar isAdmin={userData?.roles.includes(ADMIN)}>
      <SubjectSidebarContent ref={scrollingToTopRef}>
        {!isSubjectDetailsloading ? (
          <SubjectTitle ref={subjectNameHeightRef}>{getSubjectName(subjectDetails, language)}</SubjectTitle>
        ) : (
          <TitleSkeleton />
        )}
        <Link to={`/${subjectId}/overview`}>
          <OverView
            onClick={(e: React.MouseEvent) => {
              if (e.metaKey || e.ctrlKey) {
                return;
              }
              e.preventDefault();
              if (isSectionsSorted) {
                setWorningSectionsSortPopup(true);
              } else {
                navigate(`/${subjectId}/overview`);
              }
            }}
            overviewActive={overviewPath}
          >
            <OverViewIcon overviewActive={overviewPath} />
            <OverViewTitle overviewActive={overviewPath}>{tCommon("overview")}</OverViewTitle>
          </OverView>
        </Link>
        <Courses>
          <CoursesTitle>{tCommon("courses")}</CoursesTitle>
          {!isRegularInstructor && (
            <>
              {sortingActionButtons ? (
                <CoursesSaveSort>
                  <AddCourseButton onClick={cancleSortingHandler}>{tCommon("cancel")}</AddCourseButton>
                  <AddCourseButton onClick={saveSortingHandler}>{tCommon("save")}</AddCourseButton>
                </CoursesSaveSort>
              ) : (
                <CoursesIcons
                  onClick={() => {
                    if (isSectionsSorted) {
                      setWorningSectionsSortPopup(true);
                    } else {
                      setAddCoursePopup(true);
                    }
                  }}
                >
                  <PlusIcon />
                  <AddCourseButton>{tCommon("add_new")}</AddCourseButton>
                </CoursesIcons>
              )}
            </>
          )}
        </Courses>
        <Fragment>
          {!isCoursesListLoading ? (
            <Fragment>
              {coursesList?.length > 0 ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="sorted=courses">
                    {(provided) => (
                      <CoursesList className="sorted-courses" {...provided.droppableProps} ref={provided.innerRef}>
                        {sortedCourses?.map((item: any, index: any) => {
                          return (
                            <Draggable key={item?.id} draggableId={item?.id?.toString()} index={index}>
                              {(provided, snapshot) => (
                                <ChaptersWrapper
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  isDraged={snapshot.isDragging}
                                >
                                  <Link to={`/${item?.id}/course`} style={{ width: "100%" }}>
                                    <CoursesContainer
                                      onClick={(e: React.MouseEvent) => {
                                        if (e.metaKey || e.ctrlKey) {
                                          return;
                                        }
                                        e.preventDefault();
                                        if (isSectionsSorted) {
                                          setWorningSectionsSortPopup(true);
                                        } else {
                                          setCourseId(item?.id);
                                          setCancleSavingSectionSort(true);
                                          navigate(`/${item?.id}/course`);
                                        }
                                      }}
                                      active={coursePath == item?.id}
                                    >
                                      {!isRegularInstructor && (
                                        <div {...provided.dragHandleProps}>
                                          <DragDropIcon active={coursePath == item?.id} color={snapshot.isDragging} />
                                        </div>
                                      )}
                                      <CoursesInfoWrapper>
                                        <ChapterTitle active={coursePath == item?.id}>{item?.name}</ChapterTitle>
                                        {!item?.status && <ChapterState>{tCommon("inactive")}</ChapterState>}
                                      </CoursesInfoWrapper>
                                    </CoursesContainer>
                                  </Link>
                                </ChaptersWrapper>
                              )}
                            </Draggable>
                          );
                        })}
                      </CoursesList>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <EmptyCourses>{tCommon("no_course_added")}</EmptyCourses>
              )}
            </Fragment>
          ) : (
            <SubjectCoursesSkeleton />
          )}
        </Fragment>
      </SubjectSidebarContent>
    </SubjectSidebar>
  );
};

export default CoursesSideBar;
