import {
  Dot,
  DurationTime,
  DurationType,
  EditIconWrapper,
  EditWrapper,
  FreePriceTag,
  IntroDetailsWrapper,
  IntroductionContainer,
  IntroDurationWrapper,
  IntroTitle,
  IntroWrapper,
  SectionErrorMessage,
  SwitchButton,
  SwitchContainer,
  Switcher,
} from "./CourseSections.styled";
import { _UpdateSection } from "@Services/Sections";
import { CircularProgress } from "@mui/material";
import { PRO_INSTRUCTOR, ADMIN } from "constants/index";
import { useTablesFilters } from "context/TablesFilters.context";
import { TeachersDatasContext } from "context/Teachers.context";
import { userInfo } from "context/UserInfo.context";
import { EditIcon } from "images/icons/EditIcon";
import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CourseSectionsType } from "types/Courses";
import { getErrorMessage } from "utils/Forms";
import { secondsToHms } from "utils/Time";

type Props = {
  setAddIntroductionPopup: Dispatch<SetStateAction<boolean>>;
  setEditIntroductionPopup: Dispatch<SetStateAction<boolean>>;
  setSectionStatusChanged: Dispatch<SetStateAction<boolean>>;
};

const Introduction = ({ setAddIntroductionPopup, setEditIntroductionPopup, setSectionStatusChanged }: Props) => {
  const { userData } = userInfo();
  const { courseSections, refetchCourseSections } = useContext(TeachersDatasContext);
  const { isSectionsSorted, setWorningSectionsSortPopup } = useTablesFilters();
  const { t: tCommon } = useTranslation("common");

  const [introductionSection, setIntroductionSection] = useState<CourseSectionsType[]>(null);
  const [isSectionStatusLoading, setIsSectionStatusLoading] = useState<boolean>(false);
  const [changeStatusError, setChangeStatusError] = useState<string>("");
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  // Change sections and lectures status on click
  const changeStatusHandler = (id: number, currentStatus: boolean) => {
    if (!isRegularInstructor) {
      setIsSectionStatusLoading(true);
      _UpdateSection({ status: currentStatus ? 1 : 0 }, id)
        .then(() => {
          refetchCourseSections();
          setTimeout(() => {
            setSectionStatusChanged(true);
          }, 1000);
        })
        .catch((error) => {
          const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
          setChangeStatusError(errorMessage);
        })
        .finally(() => setIsSectionStatusLoading(false));
    }
  };

  // To select just introduction from sections array
  useEffect(() => {
    if (courseSections) {
      setIntroductionSection(courseSections.filter((section) => section?.is_introductory === true));
    }
  }, [courseSections]);

  return (
    <Fragment>
      {introductionSection && (
        <IntroductionContainer>
          <IntroWrapper>
            <IntroDetailsWrapper>
              <IntroTitle>{tCommon("introduction")}</IntroTitle>
              {introductionSection[0]?.lectures[0]?.media_type === "video" ? (
                <Fragment>
                  {introductionSection[0]?.lectures[0]?.media?.extra_data?.duration_in_seconds === 0 ? (
                    <IntroTitle>{tCommon("video_optamizing")}</IntroTitle>
                  ) : (
                    <IntroDurationWrapper>
                      <DurationType>{tCommon("video")}</DurationType>
                      <Dot />
                      <DurationTime>
                        {secondsToHms(introductionSection[0]?.lectures[0]?.media?.extra_data?.duration_in_seconds)}
                      </DurationTime>
                    </IntroDurationWrapper>
                  )}
                </Fragment>
              ) : (
                <IntroDurationWrapper>
                  <DurationType isEmpty={true}>{tCommon("intro_not_uploaded")}</DurationType>
                </IntroDurationWrapper>
              )}
            </IntroDetailsWrapper>
          </IntroWrapper>
          <EditWrapper>
            <FreePriceTag>{tCommon("free")}</FreePriceTag>
            <SwitchContainer isDisabled={isRegularInstructor}>
              {isSectionStatusLoading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                <SwitchButton
                  isDisabled={isRegularInstructor}
                  isActive={introductionSection[0]?.status}
                  onClick={() => {
                    if (isSectionsSorted) {
                      setWorningSectionsSortPopup(true);
                    } else {
                      changeStatusHandler(introductionSection[0]?.id, !introductionSection[0]?.status);
                    }
                  }}
                >
                  <Switcher />
                </SwitchButton>
              )}
            </SwitchContainer>
            <SectionErrorMessage hasError={Boolean(changeStatusError)}>{changeStatusError}</SectionErrorMessage>
            {!isRegularInstructor && (
              <EditIconWrapper
                onClick={() => {
                  if (isSectionsSorted) {
                    setWorningSectionsSortPopup(true);
                  } else {
                    if (introductionSection[0]?.lectures?.length === 0) {
                      setAddIntroductionPopup(true);
                    } else {
                      setEditIntroductionPopup(true);
                    }
                  }
                }}
              >
                <EditIcon color />
              </EditIconWrapper>
            )}
          </EditWrapper>
        </IntroductionContainer>
      )}
    </Fragment>
  );
};

export default Introduction;
