import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";
import PopupsLayout from "@Components/Popups/PopupsLayout";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import { _GetVimeoFolderIdStatus, _GetVimeoFolderVideosListStatus } from "@Services/Lecture";
import { _UpdateSection } from "@Services/Sections";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { TeachersDatasContext } from "context/Teachers.context";
import { userInfo } from "context/UserInfo.context";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Section } from "types/Sections";
import { getErrorMessage, removeEmptyProperties } from "utils/Forms";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  openDeletePopup: Dispatch<SetStateAction<boolean>>;
}

const EditSectionPopup = ({ isOpen, closePopup, openDeletePopup }: Props) => {
  const { userData } = userInfo();
  const { refetchCourseSections, sectionDetails, setVimeoVideosIdsStillNotReady } = useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disablebutton, setDisablebutton] = useState<boolean>(true);
  const [submitValidation, setSubmitValidation] = useState<string>("");
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Section>();

  useEffect(() => {
    if (sectionDetails) {
      reset({
        vimeo_folder_id: sectionDetails[0]?.vimeo_folder_id,
        vimeo_account: sectionDetails[0]?.vimeo_folder_id ? sectionDetails[0]?.vimeo_account : "",
        display_name: {
          en: sectionDetails[0]?.display_name?.en,
          ar: sectionDetails[0]?.display_name?.ar,
        },
      });
    }
    setSubmitValidation("");
    setDisablebutton(true);
  }, [sectionDetails, isOpen]);

  const updateSectionHandler = async (data: Section) => {
    setIsLoading(true);
    setSubmitValidation("");

    const filteredData: Section = removeEmptyProperties(data);

    if (!sectionDetails?.length) {
      setIsLoading(false);
      return;
    }

    const watchedVimeoFolderId = watch("vimeo_folder_id");
    const existingVimeoFolderId = sectionDetails[0]?.vimeo_folder_id;

    if (watchedVimeoFolderId && existingVimeoFolderId !== watchedVimeoFolderId) {
      const vimeoToken =
        filteredData.vimeo_account === "turkey"
          ? `bearer ${process.env.REACT_APP_VIMEO_TURKEY_ACCESS_TOKEN}`
          : `bearer ${process.env.REACT_APP_VIMEO_GLOBAL_ACCESS_TOKEN}`;

      try {
        const folderStatus = await _GetVimeoFolderIdStatus(filteredData.vimeo_folder_id, vimeoToken);

        if (!folderStatus?.uri) {
          setSubmitValidation(folderStatus?.error || tCommon("video_not_found"));
          return;
        }

        await handleEditSection(filteredData);

        const videoListStatus = await _GetVimeoFolderVideosListStatus(filteredData.vimeo_folder_id, vimeoToken);
        const vimeoVideoId = videoListStatus?.data
          ?.reduce((max, video) => (video.duration > max.duration ? video : max))
          .uri.split("/")
          .pop();

        setVimeoVideosIdsStillNotReady((prev) => [
          ...prev,
          { videoId: Number(vimeoVideoId), account: filteredData.vimeo_account },
        ]);
      } catch (error) {
        const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
        setSubmitValidation(errorMessage);
      } finally {
        setIsLoading(false);
      }
    } else {
      return await handleEditSection(filteredData);
    }
  };

  const handleEditSection = async (filteredData: Section) => {
    try {
      await _UpdateSection(filteredData, sectionDetails[0]?.id);
      refetchCourseSections();
      closePopup(false);
    } catch (error) {
      setSubmitValidation(getErrorMessage(error, tCommon("default_error_message")));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(updateSectionHandler)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("edit_section")}
          buttonTitle={tCommon("save_changes")}
          buttonState={Boolean(!isDirty && disablebutton)}
          closePopup={closePopup}
          showDeleteButton={!isRegularInstructor}
          deleteTitle={tCommon("delete_section")}
          openDelete={openDeletePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          {sectionDetails && (
            <FormWrapper>
              {!isRegularInstructor && (
                <InputsWrapper>
                  <Input
                    id="filled-basic"
                    variant="filled"
                    type="number"
                    disabled={isRegularInstructor}
                    className="noArrows"
                    label={tCommon("vimeo_folder_id")}
                    {...register("vimeo_folder_id", {
                      valueAsNumber: true,
                    })}
                  />
                  <FormControl variant="filled" fullWidth disabled={isRegularInstructor}>
                    <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                    <Controller
                      name="vimeo_account"
                      control={control}
                      defaultValue={sectionDetails ? sectionDetails[0]?.vimeo_account : ""}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="demo-simple-select"
                          IconComponent={ExpandMoreIcon}
                          MenuProps={MenuProps}
                          {...register("vimeo_account")}
                        >
                          <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                          <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </InputsWrapper>
              )}
              <MultibleLanguagesInputs
                data={sectionDetails[0]}
                isSection
                isOpen={isOpen}
                errors={errors}
                setValue={setValue}
                register={register}
                setDisablebutton={setDisablebutton}
              />
            </FormWrapper>
          )}
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default EditSectionPopup;
