import {
  HeaderWrapper,
  StyledTable,
  TableBody,
  TableBodyth,
  TableBodyTr,
  TableHead,
  TableHeadTh,
  TableHeadTr,
} from "./TableLayout.styled";
import theme from "@Style/Theme";
import { flexRender } from "@tanstack/react-table";
import SortingIcon from "images/icons/SortingIcon";
import { Link, useNavigate } from "react-router-dom";

type Props = {
  table: any;
  link?: string;
};

const TableLayout = ({ table, link }: Props) => {
  const navigate = useNavigate();
  const chooseTeacherHandler = (id) => {
    navigate(`/${id}/${link}`);
  };
  return (
    <StyledTable>
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableHeadTr key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => (
              <TableHeadTh key={index} onClick={header.column.getToggleSortingHandler()}>
                <HeaderWrapper>
                  {{
                    asc: (
                      <SortingIcon
                        arrowTopColor={header.id === "display_name" ? theme?.colors.gray5 : theme?.colors.gray3}
                        arrowBottomColor={header.id === "display_name" ? theme?.colors.gray3 : theme?.colors.gray5}
                      />
                    ),
                    desc: (
                      <SortingIcon
                        arrowTopColor={header.id === "display_name" ? theme?.colors.gray3 : theme?.colors.gray5}
                        arrowBottomColor={header.id === "display_name" ? theme?.colors.gray5 : theme?.colors.gray3}
                      />
                    ),
                  }[header.column.getIsSorted() as string] ?? (
                    <SortingIcon arrowBottomColor={theme?.colors.gray5} arrowTopColor={theme?.colors.gray5} />
                  )}
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </HeaderWrapper>
              </TableHeadTh>
            ))}
          </TableHeadTr>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row, index) => (
          <TableBodyTr
            key={index}
            onClick={(e) => {
              if (e.metaKey || e.ctrlKey) {
                return;
              }
              chooseTeacherHandler(row?.original.id);
            }}
          >
            {row.getVisibleCells().map((cell) => (
              <TableBodyth key={cell.id}>
                <Link to={`/${row?.original?.id}/${link}`}>
                  <div className={`link_style ${cell.id.includes("remaining_balance") && "is_balance"}`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </Link>
              </TableBodyth>
            ))}
          </TableBodyTr>
        ))}
      </TableBody>
    </StyledTable>
  );
};

export default TableLayout;
