import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";
import PopupsLayout from "@Components/Popups/PopupsLayout";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import { _GetVimeoFolderIdStatus, _GetVimeoFolderVideosListStatus } from "@Services/Lecture";
import { _CreateSection } from "@Services/Sections";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { TeachersDatasContext } from "context/Teachers.context";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Section } from "types/Sections";
import { getErrorMessage, removeEmptyProperties } from "utils/Forms";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  openAlertPopup: Dispatch<SetStateAction<boolean>>;
}

const AddSectionPopup = ({ isOpen, closePopup, openAlertPopup }: Props) => {
  const { courseId, refetchCourseSections, vimeoVideosIdsStillNotReady, setVimeoVideosIdsStillNotReady } =
    useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitValidation, setSubmitValidation] = useState<string>("");

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    formState: { isDirty, errors },
  } = useForm<Section>();

  useEffect(() => {
    if (!isOpen) {
      reset();
      setSubmitValidation("");
    }
  }, [isOpen]);

  const createSectionHandler = async (data: Section) => {
    setIsLoading(true);
    setSubmitValidation("");

    const filteredData: Section = removeEmptyProperties(data);

    if (!filteredData.vimeo_folder_id) {
      return await handleSectionCreation(filteredData);
    }

    const vimeoToken =
      filteredData.vimeo_account === "turkey"
        ? `bearer ${process.env.REACT_APP_VIMEO_TURKEY_ACCESS_TOKEN}`
        : `bearer ${process.env.REACT_APP_VIMEO_GLOBAL_ACCESS_TOKEN}`;

    try {
      const folderStatus = await _GetVimeoFolderIdStatus(filteredData.vimeo_folder_id, vimeoToken);

      if (!folderStatus?.uri) {
        setSubmitValidation(folderStatus?.error || tCommon("video_not_found"));
        return;
      }

      await handleSectionCreation(filteredData);

      const videoListStatus = await _GetVimeoFolderVideosListStatus(filteredData.vimeo_folder_id, vimeoToken);
      const vimeoVideoId = videoListStatus?.data
        ?.reduce((max, video) => (video.duration > max.duration ? video : max))
        .uri.split("/")
        .pop();

      setVimeoVideosIdsStillNotReady((prev) => [
        ...prev,
        { videoId: Number(vimeoVideoId), account: filteredData.vimeo_account },
      ]);
    } catch (error) {
      const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
      setSubmitValidation(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSectionCreation = async (filteredData: Section) => {
    try {
      await _CreateSection({ ...filteredData, course_id: courseId });
      refetchCourseSections();
      closePopup(false);
      if (!filteredData?.vimeo_folder_id) {
        openAlertPopup(true);
      }
    } catch (error) {
      setSubmitValidation(getErrorMessage(error, tCommon("default_error_message")));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(createSectionHandler)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("add_new_section")}
          buttonTitle={tCommon("add_section")}
          buttonState={!isDirty}
          closePopup={closePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            <InputsWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                type="number"
                className="noArrows"
                label={tCommon("vimeo_folder_id")}
                {...register("vimeo_folder_id", {
                  valueAsNumber: true,
                })}
                inputProps={{
                  step: "1",
                  onWheel: (e) => e.currentTarget.blur(),
                  onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.replace(/^0+(\d)/, "$1").replace(/[^0-9]/g, "");
                  },
                }}
              />
              <FormControl variant="filled" fullWidth error={Boolean(errors.vimeo_account)}>
                <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                <Controller
                  name="vimeo_account"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="demo-simple-select"
                      IconComponent={ExpandMoreIcon}
                      MenuProps={MenuProps}
                      {...register("vimeo_account", {
                        required: watch("vimeo_folder_id") ? true : false,
                      })}
                    >
                      <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                      <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </InputsWrapper>
            <MultibleLanguagesInputs
              isSection
              isOpen={isOpen}
              errors={errors}
              setValue={setValue}
              register={register}
            />
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default AddSectionPopup;
