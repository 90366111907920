import Axios from "@Configs/Axios";
import { Course, SortedCoursesTypes } from "types/Courses";

export const _CreateCourse = (data: Course) => Axios.post(`${process.env.REACT_APP_URL_PHP_API}/v1/courses`, data);

export const _UpdateCourse = (courseId: number, data: Course) =>
  Axios.put(`${process.env.REACT_APP_URL_PHP_API}/v1/courses/${courseId}`, data);

export const _DeleteCourse = (courseId: number) =>
  Axios.delete(`${process.env.REACT_APP_URL_PHP_API}/v1/courses/${courseId}`);

export const _GetCourseDetails = (courseId: number) =>
  Axios.get(`${process.env.REACT_APP_URL_PHP_API}/v1/courses/${courseId}`).then((data) => data.data.data);

export const _GetCoursesList = (subjectId: number) =>
  Axios.get(`${process.env.REACT_APP_URL_PHP_API}/v1/courses?subject_id=${subjectId}`).then((data) => data.data.data);

export const _SortingCourses = (data: SortedCoursesTypes) =>
  Axios.post(`${process.env.REACT_APP_URL_PHP_API}/v1/courses/subject-courses-order`, data);

export const _GetCourseSections = (courseId: number) =>
  Axios.get(`${process.env.REACT_APP_URL_PHP_API}/v1/courses/${courseId}/sections`).then((data) => data.data.data);
