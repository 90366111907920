import Introduction from "./Introduction";
import Section from "./Section";
import NoResultsMessage from "@Components/NoResultsMessage";
import SectionsSkeleton from "@Components/Skeletons/SectionsSkeleton";
import { _GetVimeoFolderVideosListStatus, _GetVimeoVideoStatus } from "@Services/Lecture";
import { useTablesFilters } from "context/TablesFilters.context";
import { TeachersDatasContext } from "context/Teachers.context";
import _ from "lodash";
import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { CourseSectionsType } from "types/Courses";
import { DragResult } from "types/Sections";

type Props = {
  setEditSectionPopup: Dispatch<SetStateAction<boolean>>;
  setEditLecturePopup: Dispatch<SetStateAction<boolean>>;
  setEditIntroductionPopup: Dispatch<SetStateAction<boolean>>;
  setAddIntroductionPopup: Dispatch<SetStateAction<boolean>>;
};

const CourseSections = ({
  setEditSectionPopup,
  setEditLecturePopup,
  setEditIntroductionPopup,
  setAddIntroductionPopup,
}: Props) => {
  const { setIsSectionsSorted, setWorningSortType } = useTablesFilters();
  const {
    courseSections,
    newData,
    setNewData,
    isCourseDetailsLoading,
    refetchCourseSections,
    setSectionSortSaveBtn,
    setSortedCollection,
    vimeoVideosIdsStillNotReady,
    setVimeoVideosIdsStillNotReady,
    refetchCourseDetails,
    refetchCoursesList,
  } = useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  const [sectionsList, setSectionsList] = useState<CourseSectionsType[]>();
  const [noSectionsResults, setNoSectionsResults] = useState<boolean>(false);
  const [retryCounts, setRetryCounts] = useState<{ [key: number]: number }>({});
  const [sectionStatusChanged, setSectionStatusChanged] = useState<boolean>(false);

  // To check if all sections are inactive to refetch course details
  useEffect(() => {
    if (sectionStatusChanged) {
      if (courseSections && courseSections?.every((section) => section?.status === false)) {
        refetchCourseDetails();
        refetchCoursesList();
      }
      setSectionStatusChanged(false);
    }
  }, [courseSections, sectionStatusChanged]);

  // To collect all video that have duration 0
  useEffect(() => {
    if (courseSections) {
      setVimeoVideosIdsStillNotReady((prev) => {
        const newIds = courseSections
          .flatMap((section) =>
            section.lectures.filter((lecture) => lecture?.media?.extra_data?.duration_in_seconds === 0)
          )
          .map((lec) => ({
            videoId: Number(lec?.media.extra_data.vimeo_id),
            account: lec?.media?.extra_data?.vimeo_account,
          }));
        const mergedIds = [...prev, ...newIds].reduce((acc, curr) => {
          if (!acc.some((item) => item.videoId === curr.videoId)) {
            acc.push(curr);
          }
          return acc;
        }, []);

        return mergedIds;
      });
    }
  }, [courseSections]);

  // Checked status on all colledted ids on vimeo apis
  const { data } = useQuery(
    ["vimeoids", vimeoVideosIdsStillNotReady],
    async () => {
      return Promise.all(
        vimeoVideosIdsStillNotReady.map(async (lecture) => {
          try {
            const value = await _GetVimeoVideoStatus(
              lecture.videoId,
              lecture.account === "turkey"
                ? `bearer ${process.env.REACT_APP_VIMEO_TURKEY_ACCESS_TOKEN}`
                : `bearer ${process.env.REACT_APP_VIMEO_GLOBAL_ACCESS_TOKEN}`
            );

            if (value?.transcode?.status === "complete") {
              setTimeout(() => {
                refetchCourseSections();
              }, 5000);

              setVimeoVideosIdsStillNotReady((prev) => prev.filter((vid) => vid.videoId !== lecture.videoId));
            }
          } catch (error) {
            setRetryCounts((prev) => ({
              ...prev,
              [lecture.videoId]: (prev[lecture.videoId] || 0) + 1,
            }));
          }
        })
      );
    },
    {
      enabled: vimeoVideosIdsStillNotReady?.length > 0,
      refetchInterval: 30000,
      refetchIntervalInBackground: true,
      retry: false,
    }
  );

  // Remove videos that have exceeded the retry limit
  useEffect(() => {
    setVimeoVideosIdsStillNotReady((prev) => prev.filter((vid) => (retryCounts[vid.videoId] || 0) < 3));
  }, [retryCounts]);

  // To reordering items
  const reorder = <T,>(list: T[], startIndex: number, endIndex: number): T[] => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // To reorder items within and across lists
  const reorderQuoteMap = ({
    quoteMap,
    source,
    destination,
  }: {
    quoteMap: Record<string, any[]>;
    source: { index: number; droppableId: string };
    destination: { index: number; droppableId: string };
  }) => {
    const current = [...quoteMap[source.droppableId]];
    const next = [...quoteMap[destination.droppableId]];
    const target = current[source.index];

    // If dragging within the same droppable list
    if (source.droppableId === destination.droppableId) {
      return {
        quoteMap: {
          ...quoteMap,
          [source.droppableId]: reorder(current, source.index, destination.index),
        },
      };
    }

    // Moving across different droppable lists
    current.splice(source.index, 1); // Remove the item from the source list
    next.splice(destination.index, 0, target); // Insert it into the destination list

    return {
      quoteMap: {
        ...quoteMap,
        [source.droppableId]: current, // Update source list.
        [destination.droppableId]: next, // Update destination list.
      },
    };
  };

  const onDragEnd = (result: DragResult) => {
    // Case 1: When items are combined (not just reordered)
    if (result.combine) {
      if (result.type === "COLUMN") {
        // Remove the column from the ordered list
        const updatedOrdered = newData.ordered.filter((_, index) => index !== result.source.index);
        setNewData((prev) => ({ ...prev, ordered: updatedOrdered }));
        return;
      }
      // Remove an item from a column list
      const column = newData.columns[result.source.droppableId] ?? [];
      const updatedColumn = column.filter((_, index) => index !== result.source.index);
      setNewData((prev) => ({
        ...prev,
        columns: { ...prev.columns, [result.source.droppableId]: updatedColumn },
      }));
      return;
    }

    // Case 2: No valid destination
    if (!result.destination) return;
    const { source, destination } = result;

    // Case 3: If the item is dropped at the same position, do nothing
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // Case 4: Handling column reordering
    if (result.type === "COLUMN") {
      setNewData((prev) => ({
        ...prev,
        ordered: reorder(prev.ordered, source.index, destination.index),
      }));
      setSectionSortSaveBtn(true);
      setIsSectionsSorted(true);
      setWorningSortType("sections");
      return;
    }

    // Case 5: Reordering items within or across columns
    const updatedColumns = reorderQuoteMap({
      quoteMap: newData.columns,
      source,
      destination,
    }).quoteMap;

    setNewData((prev) => ({
      ...prev,
      columns: updatedColumns,
    }));
    setSectionSortSaveBtn(true);
    setIsSectionsSorted(true);
    setWorningSortType("sections");
  };

  // To filter interoduction section from sections array
  useEffect(() => {
    if (courseSections) {
      setSectionsList(courseSections?.filter((section) => section?.is_introductory === false));
    }
  }, [courseSections]);

  // To delay no results display
  useEffect(() => {
    if (sectionsList?.length === 0) {
      setTimeout(() => {
        setNoSectionsResults(true);
      }, 700);
    }
  }, [sectionsList]);

  // To update sorted sections and lectures order
  useEffect(() => {
    if (!newData?.ordered) return;

    const updatedOrderedData = newData.ordered.map((item) => Number(item.slice(1)));

    const sortedCollectionVAR = _.sortBy(sectionsList, (item) => updatedOrderedData.indexOf(item.id));

    const updatedCollection = sortedCollectionVAR.map((section, index) => {
      const lectures = newData?.columns[section.id] || [];

      return {
        ...section,
        lectures: lectures.map((lecture, lectureIndex) => ({
          ...lecture,
          order_id: lectureIndex,
          lecture_id: lecture.id,
        })),
        section_id: section.id,
        order_id: index,
      };
    });

    setSortedCollection(updatedCollection);
  }, [sectionsList, newData]);

  return (
    <Fragment>
      {!isCourseDetailsLoading ? (
        <Fragment>
          <Introduction
            setAddIntroductionPopup={setAddIntroductionPopup}
            setEditIntroductionPopup={setEditIntroductionPopup}
            setSectionStatusChanged={setSectionStatusChanged}
          />
          {sectionsList?.length > 0 && newData ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="board" type="COLUMN" direction="vertical" isCombineEnabled={false}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {newData?.ordered?.map((key, index) => (
                      <Draggable key={key[1]} draggableId={key[1]} index={index}>
                        {(provided, snapshot) => (
                          <Section
                            data={newData}
                            keyId={key[1]}
                            keyName={key[0]}
                            refProvid={provided}
                            snapShots={snapshot}
                            setEditSectionPopup={setEditSectionPopup}
                            setEditLecturePopup={setEditLecturePopup}
                            setSectionStatusChanged={setSectionStatusChanged}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <Fragment>
              {noSectionsResults && <NoResultsMessage isCoursePage message={tCommon("no_content_added")} />}
            </Fragment>
          )}
        </Fragment>
      ) : (
        <SectionsSkeleton />
      )}
    </Fragment>
  );
};

export default CourseSections;
