import Axios from "@Configs/Axios";
import Cookies from "js-cookie";
import { phoneSMSWithRecaptcha, phoneVerify } from "types/Phone";

export const phoneSMSHandlerWithRecaptcha = ({ recaptcha, ...data }: phoneSMSWithRecaptcha) =>
  Axios.post(`${process.env.REACT_APP_BAIMS_API}/v2/phone/sms`, data, {
    headers: {
      recaptcha,
    },
  });

export const phoneAuthHandler = (data: phoneVerify) =>
  Axios.post(`${process.env.REACT_APP_BAIMS_API}/v1/phone/verify`, data, {
    headers: {
      Authorization: `Bearer ${Cookies.get("BAIMS_TOKEN")}`,
    },
  });
