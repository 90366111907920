import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";
import { PopupSectionTitle } from "@Components/Popups/Lecture/AddLecturePopup/AddLecturePopup.styled";
import PopupsLayout from "@Components/Popups/PopupsLayout";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import { _CreateCourse } from "@Services/Courses";
import { _GetVimeoFolderIdStatus, _GetVimeoFolderVideosListStatus } from "@Services/Lecture";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { TeachersDatasContext } from "context/Teachers.context";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Course } from "types/Courses";
import { getErrorMessage, removeEmptyProperties } from "utils/Forms";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  setAddNewCourseData: Dispatch<SetStateAction<Course>>;
}

const AddCoursePopup = ({ isOpen, closePopup, setAddNewCourseData }: Props) => {
  const {
    subjectId,
    refetchCoursesList,
    refetchSubjectDetails,
    setVimeoVideosIdsStillNotReady,
    teacherId,
    subjectDetails,
    setCourseAccessPopup,
  } = useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitValidation, setSubmitValidation] = useState<string>("");

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
    formState: { errors, isDirty },
  } = useForm<Course>();

  useEffect(() => {
    if (!isOpen) {
      reset();
      setSubmitValidation("");
    }
  }, [isOpen]);

  const createCourseHandler = async (data: Course) => {
    setIsLoading(true);
    setSubmitValidation("");

    const filteredData: Course = removeEmptyProperties(data);

    if (subjectDetails?.subscribers_count > 0 && data?.price !== 0) {
      setAddNewCourseData({ subject_id: subjectId, instructor_id: teacherId, ...filteredData } as Course);
      setIsLoading(false);
      setCourseAccessPopup(true);
    } else {
      if (!filteredData.vimeo_folder_id) {
        return await handleCourseCreation(filteredData);
      }

      const vimeoToken =
        filteredData.vimeo_account === "turkey"
          ? `bearer ${process.env.REACT_APP_VIMEO_TURKEY_ACCESS_TOKEN}`
          : `bearer ${process.env.REACT_APP_VIMEO_GLOBAL_ACCESS_TOKEN}`;

      try {
        const folderStatus = await _GetVimeoFolderIdStatus(filteredData.vimeo_folder_id, vimeoToken);

        if (!folderStatus?.uri) {
          setSubmitValidation(folderStatus?.error || tCommon("video_not_found"));
          return;
        }

        await handleCourseCreation(filteredData);

        const videoListStatus = await _GetVimeoFolderVideosListStatus(filteredData.vimeo_folder_id, vimeoToken);
        const vimeoVideoId = videoListStatus?.data
          ?.reduce((max, video) => (video.duration > max.duration ? video : max))
          .uri.split("/")
          .pop();

        setVimeoVideosIdsStillNotReady((prev) => [
          ...prev,
          { videoId: Number(vimeoVideoId), account: filteredData.vimeo_account },
        ]);
      } catch (error) {
        const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
        setSubmitValidation(errorMessage);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCourseCreation = async (filteredData: Course) => {
    try {
      await _CreateCourse({
        subject_id: subjectId,
        instructor_id: teacherId,
        apply_free_subscription: false,
        ...filteredData,
      } as Course);
      reset();
      refetchCoursesList();
      closePopup(false);
      setTimeout(() => {
        refetchSubjectDetails();
      }, 3000);
    } catch (error) {
      setSubmitValidation(getErrorMessage(error, tCommon("default_error_message")));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(createCourseHandler)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("add_new_course")}
          buttonTitle={tCommon("add_course")}
          buttonState={!isDirty}
          closePopup={closePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            <PopupSectionTitle noBorder>{tCommon("main_details")}</PopupSectionTitle>
            <MultibleLanguagesInputs isCourse isOpen={isOpen} setValue={setValue} errors={errors} register={register} />
            <PopupSectionTitle>{tCommon("pricing_title")}</PopupSectionTitle>
            <Input
              id="filled-basic"
              variant="filled"
              type="number"
              className="noArrows"
              label={tCommon("course_cost")}
              {...register("price", {
                required: tCommon("required_message"),
                valueAsNumber: true,
              })}
              inputProps={{
                step: "1",
                onWheel: (e) => e.currentTarget.blur(),
                onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.replace(/^0+(\d)/, "$1").replace(/[^0-9]/g, "");
                },
              }}
              error={Boolean(errors.price)}
              helperText={errors.price && errors.price?.message}
            />
            <PopupSectionTitle>{tCommon("internal_details")}</PopupSectionTitle>
            <Input
              id="filled-basic"
              variant="filled"
              label={tCommon("link")}
              {...register("link", {
                pattern: {
                  // This pattern for [ No spaces , No Arabic letters , No links ]
                  value: /^(?!.*[\u0600-\u06FF])(?!.*(?:https?:\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?!.*\s).+$/,
                  message: tCommon("course_link_validation"),
                },
              })}
              error={Boolean(errors.link)}
              helperText={errors.link && errors.link?.message}
            />
            <Input
              id="filled-basic"
              variant="filled"
              label={tCommon("internal_name")}
              {...register("name", {
                required: tCommon("required_message"),
              })}
              error={Boolean(errors.name)}
              helperText={errors.name && errors.name?.message}
            />
            <PopupSectionTitle>{tCommon("add_content")}</PopupSectionTitle>
            <InputsWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                type="number"
                className="noArrows"
                label={tCommon("vimeo_folder_id")}
                {...register("vimeo_folder_id", {
                  valueAsNumber: true,
                })}
                inputProps={{
                  step: "1",
                  onWheel: (e) => e.currentTarget.blur(),
                  onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.replace(/^0+(\d)/, "$1").replace(/[^0-9]/g, "");
                  },
                }}
              />
              <FormControl variant="filled" fullWidth error={Boolean(errors?.vimeo_account)}>
                <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                <Controller
                  name="vimeo_account"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="demo-simple-select"
                      IconComponent={ExpandMoreIcon}
                      MenuProps={MenuProps}
                      {...register("vimeo_account", {
                        required: watch("vimeo_folder_id") ? true : false,
                      })}
                    >
                      <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                      <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </InputsWrapper>
            <PopupSectionTitle>{tCommon("status")}</PopupSectionTitle>
            <FormControl variant="filled" fullWidth error={Boolean(errors.status)}>
              <InputLabel id="demo-simple-select-filled-label">{tCommon("state")}</InputLabel>
              <Controller
                name="status"
                control={control}
                defaultValue={1}
                rules={{
                  required: tCommon("required_message"),
                }}
                render={({ field }) => (
                  <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                    <MenuItem value={1}>{tCommon("active")}</MenuItem>
                    <MenuItem value={0}>{tCommon("inactive")}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default AddCoursePopup;
