import {
  AddButtonText,
  AddButtonWrapper,
  AddingActionButtonsWrapper,
  CourseContentTitle,
  CourseContentWraper,
} from "./CourseContent.styled";
import { AddCourseButton, CoursesSaveSort } from "@Components/Sidebar/components/CoursesSideBar/CoursesSideBar.styled";
import { _SortingSectionsAndLectures } from "@Services/Sections";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { useTablesFilters } from "context/TablesFilters.context";
import { TeachersDatasContext } from "context/Teachers.context";
import { userInfo } from "context/UserInfo.context";
import { BigPlusIcon } from "images/icons/BigPlusIcon";
import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { sortingSectionDataFormat } from "utils/Strings";

type Props = {
  openSectionPopup: Dispatch<SetStateAction<boolean>>;
  openLecturePopup: Dispatch<SetStateAction<boolean>>;
};

const CourseContent = ({ openSectionPopup, openLecturePopup }: Props) => {
  const { userData } = userInfo();
  const { setIsSectionsSorted, setWorningSectionsSortPopup } = useTablesFilters();
  const {
    courseSections,
    sectionSortSaveBtn,
    setSectionSortSaveBtn,
    courseId,
    sortedCollection,
    refetchCourseSections,
  } = useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  const [disableLectureBtn, setDisableLectureBtn] = useState<boolean>(false);
  const [isThereSections, setIsThereSections] = useState([]);
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  useEffect(() => {
    if (courseSections) {
      setIsThereSections(courseSections.filter((section) => section?.is_introductory === false));
    }
  }, [courseSections]);

  useEffect(() => {
    if (isThereSections?.length !== 0) {
      setDisableLectureBtn(false);
    } else {
      setDisableLectureBtn(true);
    }
  }, [isThereSections]);

  const saveSortingHandler = () => {
    _SortingSectionsAndLectures(courseId, {
      sections: sortingSectionDataFormat([...sortedCollection]),
    });
    setIsSectionsSorted(false);
    setWorningSectionsSortPopup(false);
  };

  const cancleSortingHandler = () => {
    refetchCourseSections();
    setIsSectionsSorted(false);
    setWorningSectionsSortPopup(false);
  };

  return (
    <CourseContentWraper>
      <CourseContentTitle>{tCommon("course_content")}</CourseContentTitle>
      {!isRegularInstructor && (
        <AddingActionButtonsWrapper>
          {sectionSortSaveBtn ? (
            <CoursesSaveSort isSection>
              <AddCourseButton
                onClick={() => {
                  setSectionSortSaveBtn(false);
                  cancleSortingHandler();
                }}
              >
                {tCommon("cancel")}
              </AddCourseButton>
              <AddCourseButton
                onClick={() => {
                  setSectionSortSaveBtn(false);
                  saveSortingHandler();
                }}
              >
                {tCommon("save")}
              </AddCourseButton>
            </CoursesSaveSort>
          ) : (
            <Fragment>
              <AddButtonWrapper>
                <BigPlusIcon isDisabled={disableLectureBtn} />
                <AddButtonText disabled={disableLectureBtn} onClick={() => openLecturePopup(true)}>
                  {tCommon("add_lecture")}
                </AddButtonText>
              </AddButtonWrapper>
              <AddButtonWrapper>
                <BigPlusIcon />
                <AddButtonText onClick={() => openSectionPopup(true)}>{tCommon("add_section")}</AddButtonText>
              </AddButtonWrapper>
            </Fragment>
          )}
        </AddingActionButtonsWrapper>
      )}
    </CourseContentWraper>
  );
};

export default CourseContent;
