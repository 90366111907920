import { VideoPosition, VideoWrapper } from "../AddLecturePopup/AddLecturePopup.styled";
import PopupsLayout from "@Components/Popups/PopupsLayout";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import { _GetVimeoVideoStatus, _UpdateIntroduction } from "@Services/Lecture";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Vimeo from "@u-wave/react-vimeo";
import { VIDEO } from "constants/Types";
import { TeachersDatasContext } from "context/Teachers.context";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CourseSectionsType } from "types/Courses";
import { Lecture } from "types/Lecture";
import { getErrorMessage } from "utils/Forms";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
}

const EditIntroductionPopup = ({ isOpen, closePopup }: Props) => {
  const { courseSections, refetchCourseSections } = useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitValidation, setSubmitValidation] = useState<string>("");
  const [introductionSection, setIntroductionSection] = useState<CourseSectionsType[]>([]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<Lecture>();

  useEffect(() => {
    if (introductionSection?.length > 0) {
      reset({
        vimeo_id: Number(introductionSection[0]?.lectures[0]?.media?.extra_data?.vimeo_id),
        vimeo_account: introductionSection[0]?.lectures[0]?.media?.extra_data?.vimeo_id
          ? introductionSection[0]?.lectures[0]?.media?.extra_data?.vimeo_account
          : "",
      });
    }
    setSubmitValidation("");
  }, [introductionSection, isOpen]);

  useEffect(() => {
    if (courseSections) {
      setIntroductionSection(courseSections.filter((section) => section?.is_introductory === true));
    }
  }, [courseSections]);

  const updateIntroductionHandler = async (data: Lecture) => {
    setIsLoading(true);

    if (!introductionSection?.length) {
      setIsLoading(false);
      return;
    }

    const lectureId = introductionSection[0]?.lectures[0]?.id;
    const token =
      data?.vimeo_account === "turkey"
        ? `bearer ${process.env.REACT_APP_VIMEO_TURKEY_ACCESS_TOKEN}`
        : `bearer ${process.env.REACT_APP_VIMEO_GLOBAL_ACCESS_TOKEN}`;

    try {
      const videoStatus = await _GetVimeoVideoStatus(data?.vimeo_id, token);

      if (videoStatus?.status !== "available") {
        setSubmitValidation(videoStatus?.error || tCommon("video_not_found"));
        return;
      }

      await _UpdateIntroduction(data as Lecture, lectureId);
      refetchCourseSections();
      closePopup(false);
    } catch (error) {
      setSubmitValidation(getErrorMessage(error, tCommon("default_error_message")));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(updateIntroductionHandler)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("edit_introduction")}
          buttonTitle={tCommon("save_changes")}
          buttonState={!isDirty}
          noteMessage={tCommon("intro_note_message")}
          closePopup={closePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            {introductionSection?.length > 0 && (
              <Fragment>
                <InputsWrapper>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">{tCommon("select_lecture_type")}</InputLabel>
                    <Select
                      disabled
                      id="demo-simple-select"
                      IconComponent={ExpandMoreIcon}
                      value="video"
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="video">{tCommon("video")}</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                    <Controller
                      name="vimeo_account"
                      control={control}
                      defaultValue={
                        introductionSection[0]?.lectures[0]?.media?.extra_data?.vimeo_id
                          ? introductionSection[0]?.lectures[0]?.media?.extra_data?.vimeo_account
                          : ""
                      }
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="demo-simple-select"
                          IconComponent={ExpandMoreIcon}
                          MenuProps={MenuProps}
                          {...register("vimeo_account")}
                        >
                          <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                          <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </InputsWrapper>
                <Fragment>
                  {introductionSection[0]?.lectures[0]?.media?.extra_data?.vimeo_id && (
                    <VideoPosition>
                      <VideoWrapper>
                        <Vimeo
                          key={`vimeo-player-${introductionSection[0]?.lectures[0]?.id}-${introductionSection[0]?.lectures[0]?.media?.extra_data?.vimeo_id}`}
                          video={introductionSection[0]?.lectures[0]?.media?.extra_data?.vimeo_id}
                          controls
                          width={495}
                          paused={!isOpen}
                        />
                      </VideoWrapper>
                    </VideoPosition>
                  )}
                  <Input
                    id="filled-basic"
                    variant="filled"
                    type="number"
                    className="noArrows"
                    label={tCommon("vimeo_video_id")}
                    {...register("vimeo_id", {
                      required: tCommon("required_message"),
                      valueAsNumber: true,
                    })}
                    error={Boolean(errors.vimeo_id)}
                    helperText={errors.vimeo_id && errors.vimeo_id?.message}
                  />
                </Fragment>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">{tCommon("selecting_section")}</InputLabel>
                  <Select
                    disabled
                    id="demo-simple-select"
                    IconComponent={ExpandMoreIcon}
                    value="introduction"
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="introduction">{tCommon("introduction")}</MenuItem>
                  </Select>
                </FormControl>
              </Fragment>
            )}
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default EditIntroductionPopup;
