import {
  AddLangButton,
  AddLangMenu,
  AddLangWrapper,
  CourseDetailsContainer,
  CourseLangWrapper,
  LangMenuArrow,
  LangName,
  LangTabName,
  LangTabsWrappwer,
  LangTabsWrappwers,
  MenuLangName,
  RadioBoxWrapper,
  TabContainer,
  TabsContainer,
} from "./MultibleLanguagesInputs.styled";
import { FormWrapper, Input, TrackingChapters, Wrapper } from "@Components/common/Forms";
import { ADMIN } from "constants/index";
import { TeachersDatasContext } from "context/Teachers.context";
import { userInfo } from "context/UserInfo.context";
import { BigPlusIcon } from "images/icons/BigPlusIcon";
import { RoundedCloseIcon } from "images/icons/RoundedCloseIcon";
import { RoundedDownArrowIcon } from "images/icons/RoundedDownArrowIcon";
import { Fragment, useContext, useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  // TODO: Fix the type later
  data?: any;
  isOpen: boolean;
  register: UseFormRegister<any>;
  setValue?: UseFormSetValue<any>;
  watch?: UseFormWatch<any>;
  errors: any;
  isDisabled?: boolean;
  isSection?: boolean;
  islecture?: boolean;
  isSubject?: boolean;
  isCourse?: boolean;
  setDisablebutton?: Dispatch<SetStateAction<boolean>>;
}

const MultibleLanguagesInputs = ({
  data,
  isOpen,
  register,
  errors,
  setValue,
  watch,
  isDisabled,
  isSection,
  islecture,
  isSubject,
  isCourse,
  setDisablebutton,
}: Props) => {
  const { userData } = userInfo();
  const { courseId } = useContext(TeachersDatasContext);
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");

  const [openAddLandMenu, setOpenAddLandMenu] = useState<boolean>(false);
  const [openChangeLandMenu, setOpenChangeLandMenu] = useState<boolean>(false);
  const [currentLang, setCurrentLang] = useState<string>(language === "ar" ? "Arabic" : "English");
  const [currentLangArray, setCurrentLangArray] = useState<string[]>([currentLang]);
  const [languageArray, setLanguageArray] = useState<string[]>(["English", "Arabic"]);
  const [addLanguagemenu, setAddLanguagemenu] = useState<string[]>([]);
  const isAdmin = userData?.roles.includes(ADMIN);

  // To set multible languages inputs by default based on coming data and default language
  useEffect(() => {
    if ((data?.display_name?.en || data?.name?.en) && (data?.display_name?.ar || data?.name?.ar)) {
      setCurrentLangArray(language === "ar" ? ["Arabic", "English"] : ["English", "Arabic"]);
      setCurrentLang(language === "ar" ? "Arabic" : "English");
      setAddLanguagemenu([]);
    } else if ((!data?.display_name?.en || !data?.name?.en) && (data?.display_name?.ar || data?.name?.ar)) {
      setCurrentLangArray(["Arabic"]);
      setCurrentLang("Arabic");
      setAddLanguagemenu(["English"]);
    } else if ((data?.display_name?.en || data?.name?.en) && (!data?.display_name?.ar || !data?.name?.ar)) {
      setCurrentLangArray(["English"]);
      setCurrentLang("English");
      setAddLanguagemenu(["Arabic"]);
    } else {
      setCurrentLang(language === "ar" ? "Arabic" : "English");
      setCurrentLangArray([language === "ar" ? "Arabic" : "English"]);
      const addLangList = languageArray.filter((lang) => lang !== currentLang);
      setAddLanguagemenu(addLangList);
    }
  }, [language, isOpen, data]);

  // To reset everything on closing the popup
  useEffect(() => {
    if (!isOpen) {
      setCurrentLang(language === "ar" ? "Arabic" : "English");
      setCurrentLangArray([language === "ar" ? "Arabic" : "English"]);
      setLanguageArray(["English", "Arabic"]);
      setAddLanguagemenu([]);
      setOpenAddLandMenu(false);
      setOpenChangeLandMenu(false);
    }
  }, [isOpen, courseId]);

  const changeLanguage = (language: string) => {
    const singleLanguage = languageArray.filter((lang) => lang === language);
    const addLangList = languageArray.filter((lang) => lang !== language);
    setCurrentLang(singleLanguage[0]);
    setCurrentLangArray([singleLanguage[0]]);
    setAddLanguagemenu(addLangList);
  };

  const addNewLanguage = (language: string) => {
    setAddLanguagemenu(addLanguagemenu.filter((lang) => lang !== language));
    currentLangArray.push(language);
  };

  const removeLanguage = (language: string) => {
    const langKey = language === "Arabic" ? "ar" : "en";

    try {
      setCurrentLangArray(currentLangArray.filter((lang) => lang !== language));
      setAddLanguagemenu([...addLanguagemenu, language]);
      setDisablebutton?.(false);

      if (isSubject && langKey && setValue) {
        setValue(`label.${langKey}`, undefined);
        setValue(`name.${langKey}`, undefined);
        setValue(`content.${langKey}`, undefined);
      }
      if (isCourse && langKey && setValue) {
        setValue(`display_name.${langKey}`, undefined);
        setValue(`content.${langKey}`, undefined);
      }

      if ((isSection || islecture) && setValue) {
        setValue(`display_name.${langKey}`, undefined);
      }
      setCurrentLang(currentLangArray[0]);
    } catch (error) {
      console.error("Error in removeLanguage:", error);
    }
  };

  useEffect(() => {
    if (currentLangArray?.length === 1) {
      setCurrentLang(currentLangArray[0]);
    }
  }, [currentLangArray]);

  return (
    <CourseDetailsContainer>
      <CourseLangWrapper>
        <LangTabsWrappwer>
          {currentLangArray.length > 1 ? (
            <Fragment>
              {currentLangArray.map((lang, index) => (
                <TabsContainer onClick={() => setOpenAddLandMenu(false)} isSelected={lang === currentLang} key={index}>
                  <LangTabName isSelected={lang === currentLang} onClick={() => setCurrentLang(lang)}>
                    {lang}
                  </LangTabName>
                  <LangMenuArrow isOpen={openChangeLandMenu} onClick={() => removeLanguage(lang)}>
                    <RoundedCloseIcon xColor={lang === currentLang} />
                  </LangMenuArrow>
                </TabsContainer>
              ))}
            </Fragment>
          ) : (
            <TabContainer
              isDisabled={isDisabled}
              onClick={() => {
                if (!isDisabled) {
                  setOpenChangeLandMenu(!openChangeLandMenu);
                  setOpenAddLandMenu(false);
                }
              }}
            >
              <LangName isDisabled={isDisabled}>{currentLang}</LangName>
              <LangMenuArrow isDisabled={isDisabled} isOpen={openChangeLandMenu}>
                <RoundedDownArrowIcon color={isDisabled} />
              </LangMenuArrow>
            </TabContainer>
          )}
          <AddLangMenu isOpen={openChangeLandMenu}>
            {languageArray.map((lang: string, index: number) => (
              <RadioBoxWrapper key={index}>
                <input
                  type="radio"
                  name="rating"
                  id={`edit_course_name_${index}`}
                  value={lang}
                  checked={currentLang === lang}
                  onChange={(e) => {
                    changeLanguage(e.currentTarget.value);
                    setOpenChangeLandMenu(false);
                  }}
                />
                <MenuLangName htmlFor={`edit_course_name_${index}`}>{lang}</MenuLangName>
              </RadioBoxWrapper>
            ))}
          </AddLangMenu>
        </LangTabsWrappwer>

        <LangTabsWrappwers>
          <AddLangWrapper
            isDisabled={currentLangArray.length === 2 ? true : false || isDisabled}
            onClick={() => {
              if (!isDisabled) {
                setOpenAddLandMenu(
                  addLanguagemenu.length !== 0 || currentLangArray.length !== 2 ? !openAddLandMenu : false
                );
                setOpenChangeLandMenu(false);
              }
            }}
          >
            <BigPlusIcon isDisabled={currentLangArray.length === 2 ? true : false || isDisabled} />
            <AddLangButton isDisabled={currentLangArray.length === 2 ? true : false || isDisabled}>
              {tCommon("add_languages")}
            </AddLangButton>
          </AddLangWrapper>
          <AddLangMenu isLeft isOpen={openAddLandMenu} isDisabled={isDisabled}>
            {addLanguagemenu.map((lang: string, index: number) => (
              <MenuLangName
                onClick={() => {
                  addNewLanguage(lang);
                  setOpenAddLandMenu(false);
                }}
                key={index}
              >
                {lang}
              </MenuLangName>
            ))}
          </AddLangMenu>
        </LangTabsWrappwers>
      </CourseLangWrapper>
      {currentLang === "English" && (
        <FormWrapper>
          {isSubject && (
            <Fragment>
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("subject_name")}
                {...register("name.en", {
                  required: tCommon("required_message"),
                })}
                error={Boolean(errors.name?.en)}
                helperText={errors.name?.en && errors.name?.en?.message}
              />
              <Wrapper>
                <Input
                  id="filled-basic"
                  variant="filled"
                  label={tCommon("about_subject")}
                  multiline
                  {...register("content.en", { maxLength: 300 })}
                  inputProps={{ maxLength: 301 }}
                  error={Boolean(errors.content?.en) || Boolean(watch("content.en")?.length === 301)}
                  helperText={errors.content?.en && errors.content?.en?.message}
                />
                <TrackingChapters hasError={Boolean(watch("content.en")?.length === 301)}>
                  {watch("content.en")?.length || 0}/300 {tCommon("character")}
                </TrackingChapters>
              </Wrapper>
              {isAdmin && (
                <Wrapper>
                  <Input
                    id="filled-basic"
                    variant="filled"
                    label={tCommon("label")}
                    multiline
                    {...register("label.en", { maxLength: 25 })}
                    inputProps={{ maxLength: 26 }}
                    error={Boolean(errors.label?.en) || Boolean(watch("label.en")?.length === 26)}
                    helperText={errors.label?.en && errors.label?.en?.message}
                  />
                  <TrackingChapters hasError={Boolean(watch("label.en")?.length === 26)}>
                    {watch("label.en")?.length || 0}/25 {tCommon("character")}
                  </TrackingChapters>
                </Wrapper>
              )}
            </Fragment>
          )}

          {isCourse && (
            <Fragment>
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("public_name")}
                disabled={isDisabled}
                {...register("display_name.en", {
                  required: tCommon("required_message"),
                })}
                error={Boolean(errors.display_name?.en)}
                helperText={errors.display_name?.en && errors.display_name.en?.message}
              />
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("detailed_description")}
                disabled={isDisabled}
                {...register("content.en")}
                error={Boolean(errors.content?.en)}
                helperText={errors.content?.en && errors.content.en?.message}
              />
            </Fragment>
          )}

          {(isSection || islecture) && (
            <Input
              id="filled-basic"
              variant="filled"
              label={isSection ? tCommon("section_name") : islecture ? tCommon("name") : tCommon("public_name")}
              disabled={isDisabled}
              {...register("display_name.en", {
                required: tCommon("required_message"),
              })}
              error={Boolean(errors.display_name?.en)}
              helperText={errors.display_name?.en && errors.display_name.en?.message}
            />
          )}
        </FormWrapper>
      )}
      {currentLang === "Arabic" && (
        <FormWrapper>
          {isSubject && (
            <Fragment>
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("subject_name")}
                {...register("name.ar", {
                  required: tCommon("required_message"),
                })}
                error={Boolean(errors.name?.ar)}
                helperText={errors.name?.ar && errors.name?.ar?.message}
              />
              <Wrapper>
                <Input
                  id="filled-basic"
                  variant="filled"
                  label={tCommon("about_subject")}
                  multiline
                  {...register("content.ar", { maxLength: 300 })}
                  inputProps={{ maxLength: 301 }}
                  error={Boolean(errors.content?.ar) || Boolean(watch("content.ar")?.length === 301)}
                  helperText={errors.content?.ar && errors.content?.ar?.message}
                />
                <TrackingChapters hasError={Boolean(watch("content.ar")?.length === 301)}>
                  {watch("content.ar")?.length || 0}/300 {tCommon("character")}
                </TrackingChapters>
              </Wrapper>
              {isAdmin && (
                <Wrapper>
                  <Input
                    id="filled-basic"
                    variant="filled"
                    label={tCommon("label")}
                    multiline
                    {...register("label.ar", { maxLength: 25 })}
                    inputProps={{ maxLength: 26 }}
                    error={Boolean(errors.label?.ar) || Boolean(watch("label.ar")?.length === 26)}
                    helperText={errors.label?.ar && errors.label?.ar?.message}
                  />
                  <TrackingChapters hasError={Boolean(watch("label.ar")?.length === 26)}>
                    {watch("label.ar")?.length || 0}/25 {tCommon("character")}
                  </TrackingChapters>
                </Wrapper>
              )}
            </Fragment>
          )}

          {isCourse && (
            <Fragment>
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("public_name")}
                disabled={isDisabled}
                {...register("display_name.ar", {
                  required: tCommon("required_message"),
                })}
                error={Boolean(errors.display_name?.ar)}
                helperText={errors.display_name?.ar && errors.display_name.ar?.message}
              />
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("detailed_description")}
                disabled={isDisabled}
                {...register("content.ar")}
                error={Boolean(errors.content?.ar)}
                helperText={errors.content?.ar && errors.content.ar?.message}
              />
            </Fragment>
          )}

          {(isSection || islecture) && (
            <Input
              id="filled-basic"
              variant="filled"
              label={isSection ? tCommon("section_name") : islecture ? tCommon("name") : tCommon("public_name")}
              disabled={isDisabled}
              {...register("display_name.ar", {
                required: tCommon("required_message"),
              })}
              error={Boolean(errors.display_name?.ar)}
              helperText={errors.display_name?.ar && errors.display_name.ar?.message}
            />
          )}
        </FormWrapper>
      )}
    </CourseDetailsContainer>
  );
};

export default MultibleLanguagesInputs;
