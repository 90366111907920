import {
  CancleButton,
  DeleteButton,
  DeletePopupAlert,
  DeletePopupButtons,
  DeletePopupHeader,
  DeletePopupWrapper,
} from "./DeleteSubjectPopup.styled";
import { ErrorType, StyledRodal } from "@Components/Popups/PopupsLayout/PopupsLayout.styled";
import { _DeleteSubject } from "@Services/Subjects";
import CircularProgress from "@mui/material/CircularProgress";
import { TeachersDatasContext } from "context/Teachers.context";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "utils/Forms";

type Props = {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  subjectid: string;
};

const DeleteSubjectPopup = ({ isOpen, closePopup, subjectid }: Props) => {
  const navigate = useNavigate();
  const { teacherId } = useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  const [submitValidation, setSubmitValidation] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      setSubmitValidation("");
    }
  }, [isOpen]);

  const deleteSubjectHandler = (id: string) => {
    setIsLoading(true);
    _DeleteSubject(id)
      .then(() => {
        closePopup(false);
        navigate(`/${teacherId}/subjects`);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
        setSubmitValidation(errorMessage);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <StyledRodal isDelete visible={isOpen} onClose={() => closePopup(false)}>
      <DeletePopupWrapper>
        <DeletePopupHeader>{tCommon("delete_subject_title")}</DeletePopupHeader>
        <DeletePopupAlert hasError={submitValidation !== ""}>{tCommon("delete_subject_message")}</DeletePopupAlert>
        {submitValidation && <ErrorType>{submitValidation}</ErrorType>}
        <DeletePopupButtons>
          <CancleButton variant="lightseagule" onClick={() => closePopup(false)}>
            {tCommon("cancel")}
          </CancleButton>
          <DeleteButton variant="default" disabled={isLoading} onClick={() => deleteSubjectHandler(subjectid)}>
            {isLoading ? <CircularProgress color="inherit" size={24} /> : tCommon("delete")}
          </DeleteButton>
        </DeletePopupButtons>
      </DeletePopupWrapper>
    </StyledRodal>
  );
};

export default DeleteSubjectPopup;
