export const generateVideoThumbnail = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");
    video.autoplay = false;
    video.muted = true;
    video.src = URL.createObjectURL(file);
    video.oncanplay = () => {
      video.currentTime = 0.1;
    };
    video.onseeked = () => {
      const ctx = canvas.getContext("2d");
      if (!ctx) return reject("Canvas context not available");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      resolve(canvas.toDataURL("image/png"));
    };
    video.onerror = () => reject("Failed to load video");
  });
};
