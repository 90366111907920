import ContentLoader from "react-content-loader";
import { useTranslation } from "react-i18next";

export default function RegularHeaderSkeleton() {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height="110px"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ borderBottom: "1px solid #EFEFEF", transform: `${language === "ar" ? "scale(-1,1)" : ""}` }}
    >
      <rect x="0" y="10" rx="8" ry="8" width="32%" height="10px" />
      <rect x="0" y="40" rx="8" ry="8" width="25%" height="10px" />
      <rect x="0" y="70" rx="8" ry="8" width="28%" height="10px" />
      <rect x="77%" y="0" rx="8" ry="8" width="200" height="56px" />
    </ContentLoader>
  );
}
