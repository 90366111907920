import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";
import {
  UploadFileInput,
  DashboardContainer,
  DeleteFile,
  CenterDiv,
  ErrorMessage,
  ErrorTitle,
  FileInputWrapper,
  FileNameWrapper,
  StyledPTag,
  VideoPlaceHolder,
  VideoPosition,
  DownloadFileWrapper,
  VideoWrapper,
} from "@Components/Popups/Lecture/AddLecturePopup/AddLecturePopup.styled";
import PopupsLayout from "@Components/Popups/PopupsLayout";
import { ErrorType } from "@Components/Popups/PopupsLayout/PopupsLayout.styled";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import { _GetVimeoVideoStatus, _UpdateLecture } from "@Services/Lecture";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Vimeo from "@u-wave/react-vimeo";
import { FILE, VIDEO, docFileTypes } from "constants/Types";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { TeachersDatasContext } from "context/Teachers.context";
import { userInfo } from "context/UserInfo.context";
import { BoldCloseIcon } from "images/icons/BoldCloseIcon";
import DownloadIcon from "images/icons/DownloadIcon";
import FileIcon from "images/icons/FileIcon";
import UploadPdf from "images/icons/UploadPdf";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CourseSectionsType } from "types/Courses";
import { Lecture } from "types/Lecture";
import { getErrorMessage } from "utils/Forms";
import { downloadFile, getCourseName, isEmptyValue } from "utils/Strings";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  openDeletePopup: Dispatch<SetStateAction<boolean>>;
}
const EditLecturePopup = ({ isOpen, closePopup, openDeletePopup }: Props) => {
  const { userData } = userInfo();
  const { lectureDetails, refetchCourseSections, courseSections } = useContext(TeachersDatasContext);
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");

  // Form State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitValidation, setSubmitValidation] = useState<string>("");
  const [lectureType, setLectureType] = useState<"video" | "file" | "">();
  const [sectionsList, setSectionsList] = useState<CourseSectionsType[]>();
  const [disablebutton, setDisablebutton] = useState<boolean>(true);
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  // File State
  const [docFile, setDocFile] = useState<File>(null);
  const [uplaodNewFile, setUplaodNewFile] = useState<boolean>(false);
  const [fileValidation, setFileValidation] = useState<string>("");

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Lecture>();

  useEffect(() => {
    if (lectureDetails) {
      reset({
        vimeo_id: Number(lectureDetails?.media?.extra_data?.vimeo_id),
        vimeo_account: lectureDetails?.media?.extra_data?.vimeo_id
          ? lectureDetails?.media?.extra_data.vimeo_account
          : "",
        section_id: lectureDetails?.section_id,
        display_name: {
          en: lectureDetails?.display_name?.en,
          ar: lectureDetails?.display_name?.ar,
        },
      });
      setLectureType(lectureDetails?.media_type);
    }
    setSubmitValidation("");
    setDocFile(null);
    setDisablebutton(true);
    setUplaodNewFile(false);
    setFileValidation("");
  }, [lectureDetails, isOpen]);

  // To filter interoduction section from sections array
  useEffect(() => {
    if (courseSections) {
      setSectionsList(courseSections?.filter((section) => section?.is_introductory === false));
    }
  }, [courseSections]);

  const updateLectureHandler = (data: Lecture) => {
    setIsLoading(true);
    setFileValidation("");

    if (lectureType === FILE && docFile === null && uplaodNewFile) {
      setFileValidation(tCommon("required_message"));
      setIsLoading(false);
      return;
    }

    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (isEmptyValue(value)) return;
      formData.append(key, typeof value === "object" && value !== null ? JSON.stringify(value) : value);
    });

    if (lectureType) {
      formData.append("type", lectureType);
    }

    if (docFile && lectureType === FILE) {
      formData.append(FILE, docFile);
    }

    formData.append("_method", "PUT");

    if (lectureType === VIDEO) {
      _GetVimeoVideoStatus(
        data?.vimeo_id,
        data?.vimeo_account === "turkey"
          ? `bearer ${process.env.REACT_APP_VIMEO_TURKEY_ACCESS_TOKEN}`
          : `bearer ${process.env.REACT_APP_VIMEO_GLOBAL_ACCESS_TOKEN}`
      )
        .then((value) => {
          if (value?.status === "available") {
            _UpdateLecture(formData as Lecture, lectureDetails?.id)
              .then(() => {
                refetchCourseSections();
                closePopup(false);
              })
              .catch((error) => {
                const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
                setSubmitValidation(errorMessage);
              });
          } else {
            setSubmitValidation(value?.error || tCommon("video_not_found"));
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      _UpdateLecture(formData as Lecture, lectureDetails?.id)
        .then(() => {
          refetchCourseSections();
          closePopup(false);
        })
        .catch((error) => {
          const errorMessage = getErrorMessage(error, tCommon("default_error_message"));
          setSubmitValidation(errorMessage);
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(updateLectureHandler)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("edit_lecture")}
          buttonTitle={tCommon("save_changes")}
          buttonState={Boolean(!isDirty && disablebutton && !uplaodNewFile)}
          closePopup={closePopup}
          showDeleteButton={!isRegularInstructor}
          deleteTitle={tCommon("delete_lecture")}
          noteMessage={tCommon("Lecture_note_message")}
          openDelete={openDeletePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            {lectureDetails?.media_type ? (
              <Fragment>
                {!isRegularInstructor && (
                  <>
                    <InputsWrapper>
                      <FormControl variant="filled" fullWidth>
                        <InputLabel id="demo-simple-select-filled-label">{tCommon("select_lecture_type")}</InputLabel>
                        <Select
                          id="demo-simple-select"
                          IconComponent={ExpandMoreIcon}
                          defaultValue={"true"}
                          MenuProps={MenuProps}
                          disabled
                        >
                          <MenuItem value="true">{lectureType === VIDEO ? tCommon("video") : tCommon("file")}</MenuItem>
                        </Select>
                      </FormControl>
                      {lectureType === VIDEO && (
                        <FormControl variant="filled" fullWidth>
                          <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                          <Controller
                            name="vimeo_account"
                            control={control}
                            defaultValue={
                              lectureDetails?.media?.extra_data?.vimeo_id
                                ? lectureDetails?.media?.extra_data.vimeo_account
                                : ""
                            }
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="demo-simple-select"
                                IconComponent={ExpandMoreIcon}
                                MenuProps={MenuProps}
                                {...register("vimeo_account")}
                              >
                                <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                                <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                              </Select>
                            )}
                          />
                        </FormControl>
                      )}
                    </InputsWrapper>
                    {lectureType === VIDEO && (
                      <Fragment>
                        {lectureDetails?.media?.extra_data?.vimeo_id ? (
                          <VideoPosition>
                            <VideoWrapper>
                              <Vimeo
                                key={`vimeo-player-${lectureDetails?.id}-${lectureDetails?.media?.extra_data?.vimeo_id}`}
                                video={lectureDetails?.media?.extra_data?.vimeo_id}
                                controls
                                width={495}
                                paused={!isOpen}
                              />
                            </VideoWrapper>
                          </VideoPosition>
                        ) : (
                          <VideoPlaceHolder>
                            <ErrorTitle>{tCommon("sorry")}</ErrorTitle>
                            <ErrorMessage>{tCommon("vimeo_not_found")}</ErrorMessage>
                          </VideoPlaceHolder>
                        )}
                        <Input
                          id="filled-basic"
                          label={tCommon("vimeo_video_id")}
                          variant="filled"
                          {...register("vimeo_id", {
                            valueAsNumber: true,
                          })}
                          inputProps={{
                            step: "1",
                            onWheel: (e) => e.currentTarget.blur(),
                            onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                              e.target.value = e.target.value.replace(/^0+(\d)/, "$1").replace(/[^0-9]/g, "");
                            },
                          }}
                        />
                      </Fragment>
                    )}
                    {lectureType === FILE && (
                      <Fragment>
                        <DashboardContainer>
                          <div className="container">
                            {lectureDetails?.media?.media_link && !uplaodNewFile ? (
                              <UploadFileInput>
                                <FileInputWrapper>
                                  <FileNameWrapper>
                                    <FileIcon />
                                    <StyledPTag textEllipsis noMargin>
                                      {lectureDetails?.media?.extra_data?.file_name}
                                    </StyledPTag>
                                  </FileNameWrapper>
                                  <DownloadFileWrapper
                                    onClick={() =>
                                      downloadFile(
                                        lectureDetails?.media?.media_link,
                                        lectureDetails?.media?.extra_data?.file_name
                                      )
                                    }
                                  >
                                    <DownloadIcon />
                                  </DownloadFileWrapper>
                                  <DeleteFile type="button" onClick={() => setUplaodNewFile(true)}>
                                    <BoldCloseIcon />
                                  </DeleteFile>
                                </FileInputWrapper>
                              </UploadFileInput>
                            ) : (
                              <Fragment>
                                {docFile === null ? (
                                  <UploadFileInput>
                                    <CenterDiv>
                                      <StyledPTag>{tCommon("drop_here")}</StyledPTag>
                                      <UploadPdf />
                                    </CenterDiv>
                                    <FileUploader
                                      handleChange={(e) => setDocFile(e)}
                                      name="file"
                                      types={docFileTypes}
                                    />
                                  </UploadFileInput>
                                ) : (
                                  <UploadFileInput>
                                    <FileInputWrapper>
                                      <FileNameWrapper>
                                        <FileIcon />
                                        <StyledPTag textEllipsis noMargin>
                                          {docFile?.name}
                                        </StyledPTag>
                                      </FileNameWrapper>
                                      <DeleteFile type="button" onClick={() => setDocFile(null)}>
                                        <BoldCloseIcon />
                                      </DeleteFile>
                                    </FileInputWrapper>
                                  </UploadFileInput>
                                )}
                              </Fragment>
                            )}
                            {fileValidation && docFile === null && <ErrorType center>{fileValidation}</ErrorType>}
                          </div>
                        </DashboardContainer>
                      </Fragment>
                    )}
                    <FormControl variant="filled" fullWidth>
                      <InputLabel id="demo-simple-select-filled-label">{tCommon("selecting_section")}</InputLabel>
                      <Controller
                        name="section_id"
                        control={control}
                        defaultValue={lectureDetails?.section_id ? lectureDetails?.section_id : undefined}
                        render={({ field }) => (
                          <Select
                            {...field}
                            id="demo-simple-select"
                            IconComponent={ExpandMoreIcon}
                            MenuProps={MenuProps}
                          >
                            {sectionsList?.map((section, index) => (
                              <MenuItem key={index} value={section?.id}>
                                {getCourseName(section?.display_name, language)}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </>
                )}
                <MultibleLanguagesInputs
                  islecture
                  data={lectureDetails}
                  isOpen={isOpen}
                  setValue={setValue}
                  errors={errors}
                  register={register}
                  setDisablebutton={setDisablebutton}
                />
              </Fragment>
            ) : (
              <Fragment>
                <ErrorType>{tCommon("lecture_not_found", { count: lectureDetails?.id })}</ErrorType>
                <ErrorType>{tCommon("contact_success_team")}</ErrorType>
              </Fragment>
            )}
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default EditLecturePopup;
