import Axios from "@Configs/Axios";
import { Lecture } from "types/Lecture";

export const _CreateLecture = (data: Lecture) => Axios.post(`${process.env.REACT_APP_URL_PHP_API}/v1/lectures`, data);

export const _UpdateLecture = (data: Lecture, lectureId: number) =>
  Axios.post(`${process.env.REACT_APP_URL_PHP_API}/v1/lectures/${lectureId}`, data);

export const _UpdateIntroduction = (data: Lecture, lectureId: number) =>
  Axios.put(`${process.env.REACT_APP_URL_PHP_API}/v1/lectures/${lectureId}`, data);

export const _UpdateLectureٍStatus = (data: Lecture, lectureId: number) =>
  Axios.put(`${process.env.REACT_APP_URL_PHP_API}/v1/lectures/${lectureId}`, data);

export const _DeleteLecture = (lectureId: number) =>
  Axios.delete(`${process.env.REACT_APP_URL_PHP_API}/v1/lectures/${lectureId}`);

export const _GetVimeoVideoStatus = (id: number, token: string) =>
  fetch(`https://api.vimeo.com/me/videos/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  }).then((response) => response.json());

export const _GetVimeoFolderIdStatus = (id: number, token: string) =>
  fetch(`https://api.vimeo.com/me/projects/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  }).then((response) => response.json());

export const _GetVimeoFolderVideosListStatus = (id: number, token: string) =>
  fetch(`https://api.vimeo.com/me/projects/${id}/videos`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  }).then((response) => response.json());
